import React from "react";
import { Stack, Typography } from "@mui/material";
import people from "../../assets/people.webp";

const Banner = () => {
  return (
    <Stack
      sx={{
        display: {
          xs: "none",
          xsm: "none",
          sm: "none",
          md: "flex",
          lg: "flex",
          xl: "flex",
        },
        background: "rgb(0, 101, 255, 0.02)",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        p: 4,
      }}
    >
      <img
        src={people}
        alt=""
        style={{ height: "30vh", objectFit: "contain" }}
      />
      <Stack sx={{ textAlign: "center", mt: 5 }}>
        <Typography sx={{ maxWidth: 500 }} variant="h3">
          <b>A new frontier in fundraising.</b>
        </Typography>
        <Typography sx={{ maxWidth: 550, mt: 2 }}>
          Join our community today!
        </Typography>
      </Stack>
    </Stack>
  );
};

export default Banner;
