import React from "react";
import {
  Stack,
  Typography,
  IconButton,
  ButtonBase,
  Dialog,
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/logotext.png";
import NotificationCounter from "./notificationCounter";
import NotificationDrawer from "./nofifications";
import MobileMenu from "./mobileMenu";
import { HiArrowLongRight, HiXMark } from "react-icons/hi2";
import { setUser } from "../state/slices/user";
import { CiLogin } from "react-icons/ci";
import { menuOptions } from "../utils/config";
import { useSelector, useDispatch } from "react-redux";

import { responsiveProps } from "../pages/home";

function DashboardLayout() {
  const location = useLocation();
  let paths = location.pathname.split("/").filter(Boolean);

  const lastItem = paths[paths.length - 1];

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  const isActive = (x) => {
    return (
      (paths.length === 0 && x.location === "/") ||
      lastItem?.toLowerCase() === x.title.toLowerCase() ||
      (x.location && lastItem?.toLowerCase() === x.location)
    );
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);
  const pagetitle =
    menuOptions
      .flatMap((m) => m.options)
      .find(
        (x) =>
          (x.location && x.location === lastItem?.toLowerCase()) ||
          lastItem?.toLowerCase() === x.title.toLowerCase()
      )?.title || "Dashboard";

  const [confirmLogout, setConfirmLogout] = React.useState(false);

  const [notificationsOpen, setNotificationOpen] = React.useState(false);

  return (
    <>
      <Stack
        sx={{
          "-webkit-overflow-scrolling": "touch",
          position: "relative",
          display: responsiveProps(
            "none",
            "none",
            "flex",
            "flex",
            "flex",
            "flex"
          ),
          overflow: "hidden",
          height: "100vh",
        }}
        direction="row"
      >
        <Stack
          sx={{
            p: 4,
            height: "100vh",
            overflow: "scroll",
            borderRight: 1,
            borderColor: "rgb(0, 0, 0, 0.05)",
            background: "white",
            zIndex: 2,
            width: 250,
            pr: 3,
            pl: 3,
            display: {
              xsm: "none",
              sm: "none",
              xs: "none",
              md: "flex",
            },
          }}
        >
          <Stack
            sx={{
              p: 2,
              borderRadius: 32,
              background: "rgb(0, 0, 0)",
              mb: 3,
            }}
          >
            <img
              alt="logo"
              src={logo}
              style={{ width: "auto", height: 25, objectFit: "contain" }}
            />
          </Stack>
          {menuOptions.map((x) => {
            return (
              <Stack sx={{ mb: 1, pl: 2 }}>
                <Typography variant="caption" sx={{ mb: 2, mt: 4 }}>
                  <b>{x.section}</b>
                </Typography>
                <Stack>
                  {x.options.map((x, index) => {
                    return (
                      <ButtonBase
                        disabled={x.disabled || false}
                        onClick={() => {
                          if (x.url) {
                            window.open(x.url, "_blank");
                            return;
                          }
                          navigate(x.location || x.title.toLowerCase());
                        }}
                        sx={{
                          mb: 1,
                          borderRadius: 8,
                          p: 2,
                          borderColor: "rgb(0, 0, 0, 0.015)",
                          justifyContent: "space-between",
                          background: isActive(x)
                            ? "rgb(72, 255, 104, 0.15)"
                            : "inherit",
                          textAlign: "left",
                        }}
                      >
                        <Stack spacing={1} alignItems="center" direction="row">
                          <Stack
                            spacing={1}
                            alignItems="center"
                            direction="row"
                          >
                            {x.icon}
                            {isActive(x) ? (
                              <Typography sx={{}} variant="caption">
                                <b>{x.title}</b>
                              </Typography>
                            ) : (
                              <Typography sx={{}} variant="caption">
                                {x.title}
                              </Typography>
                            )}
                          </Stack>
                          {isActive(x) && <HiArrowLongRight />}
                        </Stack>
                      </ButtonBase>
                    );
                  })}
                </Stack>
              </Stack>
            );
          })}
        </Stack>

        <Stack sx={{ flex: 1, height: "100vh", overflow: "scroll" }}>
          <Stack
            sx={{
              p: responsiveProps(2, 2, 2, 4, 4, 4),
              borderColor: "#1D1D1D",
            }}
            alignItems="center"
            justifyContent="space-between"
            direction="row"
          >
            <Stack
              alignItems="center"
              sx={{
                display: responsiveProps(
                  "flex",
                  "flex",
                  "flex",
                  "none",
                  "none",
                  "none"
                ),
              }}
              spacing={2}
              direction="row"
            >
              <MobileMenu />
              <Typography sx={{}}>
                <b>{pagetitle}</b>
              </Typography>
            </Stack>

            <Stack
              sx={{
                display: {
                  xsm: "none",
                  sm: "none",
                  xs: "none",
                  md: "flex",
                },
              }}
              spacing={5}
              alignItems="center"
              direction="row"
            >
              <Typography variant="h6" sx={{ minWidth: 160 }}>
                <b>{pagetitle}</b>
              </Typography>
              <Typography
                onClick={() => {
                  navigate("/profile");
                }}
                variant="caption"
                sx={{
                  opacity: 0.75,
                  textDecorationLine: "underline",
                  textDecorationStyle: "dotted",
                  cursor: "pointer",
                }}
              >
                {user?.businessName || `${user?.firstName} ${user?.lastName}`}
              </Typography>
            </Stack>

            <Stack spacing={3} alignItems="center" direction="row">
              <NotificationCounter setNotificationOpen={setNotificationOpen} />

              <IconButton
                onClick={() => {
                  setConfirmLogout(true);
                }}
              >
                <CiLogin />
              </IconButton>
            </Stack>
          </Stack>
          <Outlet />
        </Stack>
      </Stack>
      <Stack
        sx={{
          display: responsiveProps(
            "flex",
            "flex",
            "none",
            "none",
            "none",
            "none"
          ),
        }}
      >
        <Stack
          sx={{
            p: responsiveProps(2, 2, 2, 4, 4, 4),
            borderColor: "#1D1D1D",
          }}
          alignItems="center"
          justifyContent="space-between"
          direction="row"
        >
          <Stack alignItems="center" spacing={2} direction="row">
            <MobileMenu />
            <Typography sx={{}}>
              <b>{pagetitle}</b>
            </Typography>
          </Stack>

          <Stack spacing={3} alignItems="center" direction="row">
            <NotificationCounter setNotificationOpen={setNotificationOpen} />

            <IconButton
              onClick={() => {
                setConfirmLogout(true);
              }}
            >
              <CiLogin />
            </IconButton>
          </Stack>
        </Stack>
        <Outlet />
      </Stack>
      <Dialog
        onClose={() => {
          setConfirmLogout(false);
        }}
        open={confirmLogout}
        PaperProps={{
          sx: {
            p: 2,
            borderRadius: 2,
          },
        }}
        fullWidth
        maxWidth="xsm"
      >
        <DialogTitle>Sign out</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you would like to sign out?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              dispatch(setUser(null));
            }}
            variant="contained"
            disableElevation
            color="primary"
            sx={{ borderRadius: 32, textTransform: "none" }}
          >
            Continue
          </Button>
          <Button
            sx={{ borderRadius: 32, textTransform: "none" }}
            color="warning"
            endIcon={<HiXMark />}
            onClick={() => {
              setConfirmLogout(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <NotificationDrawer
        open={notificationsOpen}
        setOpen={setNotificationOpen}
      />
    </>
  );
}

export default DashboardLayout;
