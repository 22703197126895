import React from "react";
import _ from "lodash";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Alert from "@mui/material/Alert";
import DialogContent from "@mui/material/DialogContent";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import { apiSlice } from "../api/api.service";
import { incrementNotificationCount } from "../state/slices/notify";
import { updateUser } from "../state/slices/user";
import { updateSnack } from "../state/slices/snack";
import { updateDialog } from "../state/slices/dialog";
import { Button, DialogActions } from "@mui/material";
import { useLazyGetUserQuery } from "../api/api-endpoints/user";
import useWebSocket from "react-use-websocket";
import { constants } from "../utils/config";
const alertTone = require("../assets/alert.mp3");

let lastReceived = null;

export default function RouteGuard() {
  const dispatch = useDispatch();

  const snack = useSelector((state) => state.snack);

  const dialog = useSelector((state) => state.dialog);

  const handleClose = (event, reason) => {
    dispatch(updateSnack({ open: false, msg: null }));
  };

  const user = useSelector((state) => state.user);

  const [getUser] = useLazyGetUserQuery();

  const { lastJsonMessage } = useWebSocket(
    encodeURI(`${constants.notifier}?connection_id=${user?.user?._id}`),
    {
      retryOnError: true,
    },
    true
  );

  const getProfile = React.useCallback(async () => {
    try {
      let result = await getUser();
      if (result?.data) {
        dispatch(updateUser({ user: result?.data }));
      }
    } catch (e) {
      console.log("error", e.message);
    }
  }, [dispatch, getUser]);

  React.useEffect(() => {
    if (!user?.user) {
      return;
    }

    if (lastJsonMessage && !_.isEqual(lastJsonMessage, lastReceived)) {
      lastReceived = lastJsonMessage;
      getProfile();
      if (lastJsonMessage?.action_code !== "user_changes") {
        dispatch(incrementNotificationCount());
        const audio = new Audio(alertTone);
        audio
          .play()
          .catch((e) => console.error("Error playing the alert tone:", e));

        if (lastJsonMessage.actionCode === "") {
        }
      } else if (lastJsonMessage?.action_code === "user_changes") {
        // dispatch(updateUser({ user: lastJsonMessage.payload }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage, dispatch, user]);

  React.useEffect(() => {
    if (user?.user) {
      getProfile();
    }
  }, [getProfile, user?.user]);

  if (!user?.user) {
    return <Navigate replace={true} to="login" />;
  }

  return (
    <>
      <Outlet />
      <Snackbar
        open={snack.open}
        autoHideDuration={5000}
        onClose={handleClose}
        message={snack.msg}
      />
      <Dialog
        onClose={() => {
          dispatch(updateDialog({ open: false, msg: null }));
        }}
        PaperProps={{
          sx: {
            background: "rgba(252, 233, 232)",
          },
        }}
        open={dialog.open}
      >
        <DialogTitle>Sorry, an error occurred.</DialogTitle>
        <DialogContent sx={{ pb: 0, mb: 0 }}>
          <Alert
            sx={{ width: 320, borderRadius: 2, mb: 0 }}
            variant="standard"
            severity="error"
          >
            {dialog.msg}
          </Alert>
        </DialogContent>
        <DialogActions sx={{ mt: 0, pt: 0 }}>
          <Button
            onClick={() => {
              dispatch(updateDialog({ open: false, msg: null }));
            }}
            color="warning"
            sx={{ textTransform: "none", m: 1 }}
          >
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
