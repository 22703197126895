import {
  CiGrid2V,
  CiCircleList,
  CiCirclePlus,
  CiBag1,
  CiFileOn,
  CiUser,
} from "react-icons/ci";
import { CiLink, CiChat2 } from "react-icons/ci";

const localUrl = "http://localhost:4000";
const localNotifier = "ws://127.0.0.1:4000";
const liveUrl = "https://monkfish-app-oh4e8.ondigitalocean.app";
const liveNotifier = "wss://monkfish-app-oh4e8.ondigitalocean.app";
const constants = {
  apiUrl: liveUrl,
  notifier: liveNotifier,
};

const menuOptions = [
  {
    section: "Navigation",
    options: [
      { title: "Dashboard", icon: <CiGrid2V size={16} />, location: "/" },
      {
        title: "My Campaigns",
        icon: <CiCirclePlus size={16} />,
        location: "campaigns",
      },
      { title: "Investments", icon: <CiBag1 size={16} /> },
      { title: "Transactions", icon: <CiCircleList size={16} /> },
      { title: "My Profile", icon: <CiUser size={16} />, location: "profile" },
    ],
  },
  {
    section: "Support",
    options: [
      { title: "Chat", icon: <CiChat2 />, url: "https://wa.me?263712138991" },

      {
        title: "Legal",
        icon: <CiFileOn />,
        url: "https://klicknown.com/legal",
      },
      { title: "Website", icon: <CiLink />, url: "https://klicknown.com" },
    ],
  },
];

export function numberWithCommas(x = "") {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export { constants, menuOptions };
