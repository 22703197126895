import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useUploadMutation } from "../api/api-endpoints/documents";
import { useSelector } from "react-redux";

import { Refresh } from "@mui/icons-material";
import {
  Box,
  Typography,
  IconButton,
  Stack,
  LinearProgress,
} from "@mui/material";
import { HiXMark } from "react-icons/hi2";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const FileUploader = ({
  files,
  setFiles,
  accept,
  maxFiles,
  resource,
  label = "",
  docType = "image",
}) => {
  const [uploadDocument] = useUploadMutation();
  const [controllers, setControllers] = React.useState([]);
  const uploadProgress = useSelector((state) => state.uploadProgress[resource]);
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length === 0) {
        return;
      }
      const newFiles = acceptedFiles.map((file) => ({
        file,
        key: Date.now() + file.name,
      }));
      if (maxFiles === 1) {
        setFiles([newFiles[0]]);
        uploadFiles([newFiles[0]]);
      } else {
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        uploadFiles(newFiles);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const uploadFiles = (files = []) => {
    files.forEach(async (item) => {
      const controller = new AbortController();
      var formdata = new FormData();
      formdata.append("file", item.file, item.file.name);
      let newControllers = [...controllers, { key: item.key, controller }];
      setControllers(newControllers);
      let result = await uploadDocument({
        data: formdata,
        key: item.key,
        controller,
        resource,
      });
      if (!result.error && result.data) {
        setFiles((x) =>
          x.map((prev) =>
            prev.key === item.key ? { ...prev, _id: result.data._id } : prev
          )
        );
      }
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept:
      docType === "image"
        ? {
            "image/*": [".jpeg", ".jpg", ".png"],
          }
        : docType === "document"
        ? {
            "application/pdf": [".pdf"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              [".docx", ".doc"],
          }
        : {
            "image/*": [".jpeg", ".jpg", ".png"],
            "application/pdf": [".pdf"],
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
              [".docx", ".doc"],
          },
    maxFiles,
  });

  const removeFile = (key) => {
    setFiles((prev) => prev.filter((file) => file.key !== key));
    controllers.forEach((controller) => {
      if (controller.key === key) {
        controller.controller.abort();
      }
    });
  };

  return (
    <Stack
      sx={{
        border: "1px dashed rgb(0, 0, 0, 0.1)",
        borderRadius: "5px",
        textAlign: "center",
      }}
    >
      {(files.length === 0 || maxFiles > 1) && (
        <Stack {...getRootProps()} sx={{ padding: "16px", cursor: "pointer" }}>
          <input {...getInputProps()} />
          <Typography variant="body2">{label}</Typography>
          {accept && (
            <Typography variant="caption">
              (Accepted types: {accept})
            </Typography>
          )}
          {maxFiles && (
            <Typography variant="caption">, Max files: {maxFiles}</Typography>
          )}
        </Stack>
      )}
      <Stack
        sx={{
          padding: files.length > 0 ? "16px" : 0,
          paddingTop: 0,
          mt: maxFiles === 1 && files.length > 0 ? 2 : 0,
        }}
      >
        {files.map(({ file, key }) => {
          let activeFileProgress = uploadProgress?.find(
            (a) => a.key === key
          ) || {
            progress: 0,
            key,
          };
          return (
            <Stack
              sx={{
                boxShadow: "2px 2px 8px 12px rgba(25, 137, 255, 0.045)",
                p: 1.5,
                borderRadius: 2,
                pb: 0.5,
                mb: 1,
                textAlign: "left",
              }}
            >
              <Stack alignItems="center" spacing={1} direction="row">
                <Typography sx={{ flex: 1 }}>{file.name}</Typography>
                <IconButton
                  onClick={() => {
                    removeFile(key);
                  }}
                >
                  <HiXMark />
                </IconButton>
              </Stack>
              {activeFileProgress.status && (
                <Stack
                  direction="row"
                  sx={{
                    p: 1.5,
                    borderRadius: 1,
                    background: "#F6E6E7",
                    justifyContent: "space-between",
                    alignItems: "center",
                    pt: 0.5,
                    pb: 0.5,
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{ fontSize: 12, color: "red" }}
                  >
                    Upload Failed
                  </Typography>
                  <IconButton
                    color="inherit"
                    size="medium"
                    onClick={async () => {
                      const controller = new AbortController();
                      controllers.forEach((controller) => {
                        if (controller.key === key) {
                          controller.controller.abort();
                        }
                      });
                      let newControllers = [
                        ...controllers,
                        { key, controller },
                      ];
                      setControllers(newControllers);
                      var formdata = new FormData();
                      formdata.append("file", file, file.name);

                      let result = await uploadDocument({
                        data: formdata,
                        key,
                        controller:
                          newControllers[newControllers.length - 1].controller,
                        resource,
                      });
                      if (!result.error && result.data) {
                        setFiles((x) =>
                          x.map((prev) =>
                            prev.key === key
                              ? { ...prev, _id: result.data._id }
                              : prev
                          )
                        );
                      }
                    }}
                  >
                    <Refresh />
                  </IconButton>
                </Stack>
              )}
              {!activeFileProgress.status && (
                <Stack
                  sx={{
                    width: 155,
                    top: 0,
                    mb: 2,
                  }}
                  direction="column"
                >
                  <LinearProgressWithLabel
                    sx={{ borderRadius: 32 }}
                    color={
                      activeFileProgress.progress === 100 ? "success" : "info"
                    }
                    value={activeFileProgress.progress}
                  />
                </Stack>
              )}
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default FileUploader;
