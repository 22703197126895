import React from "react";
import {
  IconButton,
  Stack,
  Typography,
  Avatar,
  Button,
  Skeleton,
  Chip,
  ButtonBase,
} from "@mui/material";
import IdentityVerification from "./identityVerification";
import chart2 from "../../assets/art/iconly-glass-tick.svg";
import chart3 from "../../assets/art/ic_glass_users.png";
import glassChart from "../../assets/art/iconly-glass-chart.svg";
import person2 from "../../assets/art/person-standing.webp";
import { numberWithCommas } from "../../utils/config";
import {
  FaMoneyBillWave,
  FaHandHoldingUsd,
  FaRegMoneyBillAlt,
  FaDonate,
  FaTimes,
} from "react-icons/fa";
import { useGetTransactionsQuery } from "../../api/api-endpoints/transactions";

import {
  HiArrowLongRight,
  HiArrowsUpDown,
  HiCheckBadge,
  HiChevronRight,
  HiShare,
  HiShieldCheck,
  HiWallet,
  HiXCircle,
  HiClock,
  HiExclamationCircle,
} from "react-icons/hi2";
import { IoBag } from "react-icons/io5";
import { useSelector } from "react-redux";
import {
  FundingInstructions,
  DataSafetyInfo,
  InvestmentGuide,
  AssetPurchaseGuide,
} from "./widgets";
import { useNavigate } from "react-router-dom";
import { CiWallet } from "react-icons/ci";
import { HiTrendingUp } from "react-icons/hi";

export const responsiveProps = (xs, xsm, sm, md, lg, xl) => ({
  xs,
  xsm,
  sm,
  md,
  lg,
  xl,
});

export const AccountStats = () => {
  const user = useSelector((state) => state.user?.user);

  const navigate = useNavigate();

  return (
    <Stack spacing={0.5} sx={{ flex: 1 }}>
      <Stack
        spacing={3}
        sx={{
          background: "rgb(249, 255, 0, 0.1)",
          flex: 1,
          borderRadius: 4,
          p: 4,
          pt: 2,
          pb: 2,
          borderBottomLeftRadius: 0,
        }}
        direction="row"
      >
        <Stack sx={{ flex: 1 }}>
          <HiWallet size={22} color="#009A4B" />
          <Typography sx={{ mt: 1, fontSize: 16 }}>Wallet Balance</Typography>

          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
          >
            <Typography variant="body2" sx={{}}>
              <b>${numberWithCommas(user?.accountBalance?.toFixed(2))}</b>
            </Typography>
            <IconButton
              onClick={() => {
                navigate("/investments");
              }}
            >
              <HiArrowLongRight />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        spacing={3}
        sx={{
          background: "rgb(249, 255, 0, 0.1)",
          flex: 1,
          borderRadius: 4,
          p: 4,
          pt: 2,
          pb: 2,
          borderTopLeftRadius: 0,
        }}
        direction="row"
      >
        <Stack sx={{ flex: 1 }}>
          <IoBag size={22} color="#009A4B" />
          <Typography sx={{ mt: 1, fontSize: 16 }}>Projects Funded</Typography>

          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
          >
            <Typography variant="body2" sx={{}}>
              <b>{user?.fundedProjects?.length || 0}</b>
            </Typography>
            <IconButton
              onClick={() => {
                navigate("/investments?target=marketplace");
              }}
            >
              <HiArrowLongRight />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export const FundingGuide = ({ setDrawer }) => {
  return (
    <Stack spacing={2} sx={{ flex: 1 }}>
      <Stack
        sx={{
          flex: 1,
          borderRadius: 4,
          background: "#212C9F",
          alignItems: "center",
          p: 3,
          textAlign: "center",
          justifyContent: "center",
          color: "white",
        }}
      >
        <Typography variant="caption">Guide</Typography>

        <Typography variant="h5">
          <b>Add funds to your account</b>
        </Typography>
        <Button
          onClick={() => {
            setDrawer({ open: true, component: <FundingInstructions /> });
          }}
          endIcon={<HiArrowLongRight />}
          variant="contained"
          disableElevation
          sx={{
            borderRadius: 32,
            textTransform: "none",
            mt: 2,
            height: 55,
            background: "#C1F2FE",
            color: "#222C6F",
          }}
        >
          Learn how
        </Button>
      </Stack>
    </Stack>
  );
};

export const InvestingCta = () => {
  const navigate = useNavigate();
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        background: "#172029",
        borderRadius: 4,
        p: 5,
        width: responsiveProps("100%", "100%", "100%", 540, 540, 540),
        color: "white",
      }}
    >
      <Stack sx={{ flex: 1 }}>
        <Typography
          sx={{
            fontSize: 28,
            display: responsiveProps(
              "none",
              "none",
              "flex",
              "flex",
              "flex",
              "flex"
            ),
          }}
          variant="h5"
        >
          <b>
            Keep investing, stay <br /> wealthy and smart.
          </b>
        </Typography>
        <Typography
          sx={{
            fontSize: 16,
            display: responsiveProps(
              "flex",
              "flex",
              "none",
              "none",
              "none",
              "none"
            ),
          }}
        >
          <b>
            Keep investing, stay <br /> wealthy and smart.
          </b>
        </Typography>
        <Typography sx={{ mt: 1, maxWidth: 350, opacity: 0.75 }}>
          Explore over 10 funding opportunities
        </Typography>
        <Button
          onClick={() => {
            navigate("/investments?target=marketplace");
          }}
          endIcon={<HiArrowLongRight />}
          color="warning"
          variant="contained"
          disableElevation
          sx={{
            borderRadius: 32,
            textTransform: "none",
            alignSelf: "flex-start",
            mt: 2,
            height: 55,
          }}
        >
          Explore
        </Button>
      </Stack>
      <Stack
        sx={{
          display: responsiveProps(
            "flex",
            "flex",
            "none",
            "none",
            "none",
            "none"
          ),
        }}
      >
        <img
          src={person2}
          alt="people"
          style={{ height: 65, objectFit: "contain" }}
        />
      </Stack>
      <Stack
        sx={{
          display: responsiveProps(
            "none",
            "none",
            "flex",
            "flex",
            "flex",
            "flex"
          ),
        }}
      >
        <img
          src={person2}
          alt="people"
          style={{ height: 155, objectFit: "contain" }}
        />
      </Stack>
    </Stack>
  );
};

export const SecurityCta = ({ setDrawer }) => {
  return (
    <Stack
      direction={responsiveProps(
        "row",
        "row",
        "row",
        "column",
        "column",
        "column"
      )}
      spacing={responsiveProps(0, 0, 3, 3, 3, 3)}
      sx={{ flex: 1 }}
    >
      <Stack
        sx={{
          flex: 1,
          borderRadius: 4,
          p: 3,
          background: "#DDFFEB",
          color: "black",
          display: responsiveProps(
            "none",
            "none",
            "flex",
            "flex",
            "flex",
            "flex"
          ),
        }}
      >
        <Stack alignItems="center" spacing={2} direction="row">
          <Stack>
            <HiShieldCheck size={32} />
          </Stack>
          <Stack>
            <Typography variant="h6">
              <b>Secure Core</b>
            </Typography>
            <Typography sx={{ maxWidth: 250 }}>
              At Klicknown, we got the extra mile in protecting your data.
            </Typography>
            <Button
              color="warning"
              onClick={() => {
                setDrawer({ open: true, component: <DataSafetyInfo /> });
              }}
              disableElevation
              sx={{
                borderRadius: 32,
                alignSelf: "flex-start",
                textTransform: "none",
                mt: 0.5,
              }}
            >
              Learn More
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <VerificationTab setDrawer={setDrawer} />
    </Stack>
  );
};

export const VerificationTab = ({ setDrawer }) => {
  const user = useSelector((state) => state.user?.user);
  const { verificationStatus } = user;

  let icon;
  let chipColor;
  let chipLabel;

  switch (verificationStatus) {
    case "Pending":
      icon = <HiClock size={20} />;
      chipColor = "warning";
      chipLabel = "Pending";
      break;
    case "Approved":
      icon = <HiCheckBadge size={20} />;
      chipColor = "success";
      chipLabel = "Verified";
      break;
    case "Rejected":
      icon = <HiXCircle size={20} />;
      chipColor = "error";
      chipLabel = "Rejected";
      break;
    case "Uninitiated":
      icon = <HiExclamationCircle size={20} />;
      chipColor = "default";
      chipLabel = "Uninitiated";
      break;
    default:
      icon = <HiClock size={32} />;
      chipColor = "default";
      chipLabel = "Unknown";
  }

  return (
    <Stack
      sx={{
        flex: 1,
        borderRadius: 4,
        p: 3,
        border: 1,
        borderStyle: "dashed",
        borderColor: "rgb(0, 0, 0, 0.1)",
      }}
    >
      <Typography variant="h6">
        <b>Verification Status</b>
      </Typography>
      <Typography variant="body2">
        To start investing or creating funding campaigns, your account needs to
        get verified.
      </Typography>
      <Stack sx={{ mt: 1 }} direction="row" alignItems="center" spacing={1}>
        <Chip
          icon={icon}
          color={chipColor}
          sx={{ alignSelf: "flex-start" }}
          label={chipLabel}
        />
        {verificationStatus !== "Approved" &&
          verificationStatus !== "Pending" && (
            <Button
              onClick={() => {
                setDrawer({
                  open: true,
                  component: (
                    <IdentityVerification
                      handleClose={() => {
                        setDrawer({
                          open: false,
                          component: <></>,
                        });
                      }}
                    />
                  ),
                });
              }}
              endIcon={<HiArrowLongRight />}
              sx={{ textTransform: "none", borderRadius: 32 }}
              size="medium"
            >
              {verificationStatus === "Rejected" ? "Resubmit" : "Get verified"}
            </Button>
          )}
      </Stack>
    </Stack>
  );
};

export const transactionTypes = {
  Contribution: { icon: <FaDonate />, color: "orange" },
  Topup: { icon: <CiWallet />, color: "green" },
  Repayment: { icon: <FaHandHoldingUsd />, color: "green" },
  Earnings: { icon: <HiTrendingUp />, color: "green" },
  Withdrawal: { icon: <FaMoneyBillWave />, color: "orange" },
  Fee: { icon: <FaTimes />, color: "orange" },
};

export const Transactions = () => {
  const {
    data: transactions,
    isFetching,
    isLoading,
  } = useGetTransactionsQuery({
    limit: 3,
  });

  const navigate = useNavigate();

  return (
    <Stack
      sx={{
        flex: 1,
        borderRadius: 4,
        p: 3,
        borderColor: "#2D2D2D",
        background: "rgb(0, 0, 0, 0.01)",
      }}
    >
      <Stack spacing={2} sx={{ alignItems: "center", mb: 1 }} direction="row">
        <Avatar sx={{ height: 40, width: 40, background: "white" }}>
          <HiArrowsUpDown color="black" />
        </Avatar>
        <Stack>
          <Typography>Recent Transactions</Typography>
          {transactions?.length ? (
            <Button
              onClick={() => {
                navigate("/transactions");
              }}
              endIcon={<HiArrowLongRight />}
              size="small"
              variant="outlined"
              disableElevation
              sx={{
                borderRadius: 32,
                textTransform: "none",
                alignSelf: "flex-start",
              }}
            >
              View all
            </Button>
          ) : (
            <Typography variant="caption">No transactions yet</Typography>
          )}
        </Stack>
      </Stack>
      {isFetching || isLoading
        ? Array.from({ length: 3 }).map((_, index) => (
            <Stack key={index} sx={{ mt: 3, mb: 1 }}>
              <Stack sx={{ flex: 1 }} spacing={3} direction="row">
                <Skeleton
                  animation="wave"
                  sx={{ height: 40, width: 40 }}
                  variant="circular"
                />
                <Stack sx={{ flex: 1 }}>
                  <Skeleton animation="wave" sx={{ mr: 4 }} variant="text" />
                  <Skeleton animation="wave" sx={{ mr: 8 }} variant="text" />
                </Stack>
              </Stack>
            </Stack>
          ))
        : transactions?.slice(0, 3).map((transaction, index) => (
            <Stack key={index} sx={{ mt: 3, mb: 1 }}>
              <Stack sx={{ flex: 1 }} spacing={3} direction="row">
                <Avatar
                  sx={{
                    height: 40,
                    width: 40,
                    bgcolor:
                      transactionTypes[transaction.reason]?.color || "grey",
                  }}
                >
                  {transactionTypes[transaction.reason]?.icon || (
                    <HiArrowsUpDown />
                  )}
                </Avatar>
                <Stack sx={{ flex: 1 }}>
                  <Typography sx={{ maxWidth: 400 }} variant="body2">
                    <b>{transaction.reason}</b>
                    {transaction.reason !== "Withdrawal" &&
                      transaction.project &&
                      ` - ${
                        transaction.project?.title || transaction.projectTitle
                      }`}
                  </Typography>

                  <Typography variant="caption">
                    ${numberWithCommas(transaction.amount.toFixed(0))}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          ))}
      {transactions &&
        transactions.length < 3 &&
        !isFetching &&
        Array.from({ length: 3 - transactions?.length }).map((_, index) => (
          <Stack key={index} sx={{ mt: 3, mb: 1 }}>
            <Stack sx={{ flex: 1 }} spacing={3} direction="row">
              <Skeleton
                animation={false}
                sx={{ height: 40, width: 40 }}
                variant="circular"
              />
              <Stack sx={{ flex: 1 }}>
                <Skeleton animation={false} sx={{ mr: 4 }} variant="text" />
                <Skeleton animation={false} sx={{ mr: 8 }} variant="text" />
              </Stack>
            </Stack>
          </Stack>
        ))}
    </Stack>
  );
};

export const ActionTabs = ({ setDrawer }) => {
  return (
    <Stack
      spacing={2}
      sx={{
        flex: 1,
        borderRadius: 4,
        border: 1,
        borderStyle: "dashed",
        p: 3,
        borderColor: "rgb(0, 0, 0, 0.1)",
      }}
    >
      <ButtonBase
        sx={{ borderRadius: 3 }}
        onClick={() => {
          setDrawer({ open: true, component: <InvestmentGuide /> });
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          sx={{
            p: 2,
            borderRadius: 3,
            boxShadow: "2px 2px 8px 12px rgba(25, 137, 255, 0.01)",
            textAlign: "left",
            flex: 1,
          }}
        >
          <img
            alt="logo"
            src={glassChart}
            style={{ width: "auto", height: 25, objectFit: "contain" }}
          />
          <Stack sx={{ flex: 1 }}>
            <Typography>Investment Guide</Typography>
            <Typography sx={{ maxWidth: 200 }} variant="caption">
              Learn how Klicknown works for investors
            </Typography>
          </Stack>

          <HiChevronRight />
        </Stack>
      </ButtonBase>

      <ButtonBase
        sx={{ borderRadius: 3 }}
        onClick={() => {
          setDrawer({ open: true, component: <AssetPurchaseGuide /> });
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          sx={{
            p: 2,
            borderRadius: 3,
            boxShadow: "2px 2px 8px 12px rgba(25, 137, 255, 0.01)",
            textAlign: "left",
            flex: 1,
          }}
        >
          <img
            alt="logo"
            src={chart2}
            style={{ width: "auto", height: 25, objectFit: "contain" }}
          />
          <Stack sx={{ flex: 1 }}>
            <Typography>Acquire Assets</Typography>
            <Typography variant="caption">
              Learn how we fund asset purchase on Klicknown
            </Typography>
          </Stack>

          <HiChevronRight />
        </Stack>
      </ButtonBase>

      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{
          p: 2,
          borderRadius: 3,
          //   background: "rgb(16, 16, 18)",
          boxShadow: "2px 2px 8px 12px rgba(25, 137, 255, 0.01)",
          flex: 1,
        }}
      >
        <img
          alt="logo"
          src={chart3}
          style={{ width: "auto", height: 25, objectFit: "contain" }}
        />
        <Stack sx={{ flex: 1 }}>
          <Typography>Invite Friends</Typography>
          <Typography variant="caption">
            Make the most out of your funds together with your family and
            friends
          </Typography>
        </Stack>

        <HiShare />
      </Stack>
    </Stack>
  );
};
