import React, { useState } from "react";
import {
  Stack,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Collapse,
} from "@mui/material";
import { constants } from "../../utils/config";
import { useNavigate } from "react-router-dom";
import { HiArrowLongRight, HiCheckCircle } from "react-icons/hi2";
import validator from "validator";
import people from "../../assets/people.webp";

const handleBlur = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};

const ForgotPassword = () => {
  const [formData, setFormData] = useState({
    email: "",
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "email" ? value.toLowerCase() : value,
    }));
  };
  const navigate = useNavigate();

  const [serverMessage, setServerMessage] = useState(null);

  React.useEffect(() => {
    if (serverMessage) {
      const timer = setTimeout(() => {
        setServerMessage(null);
      }, 5000); // Clears the message after 5 seconds

      return () => clearTimeout(timer); // Clearing the timer if the component unmounts
    }
  }, [serverMessage]);

  const [done, setDone] = React.useState(false);

  const login = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        `${constants.apiUrl}/users/requestPasswordReset`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...formData,
          }),
        }
      );

      const data = await response.json();

      if (data?.success) {
        setDone(true);
      } else {
        setServerMessage(data?.error || "An unexpected error occurred.");
      }
      setLoading(false);
    } catch (error) {
      console.error("An error occurred while trying to login:", error);
      setServerMessage("An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack
      direction={{
        xs: "column",
        xsm: "column",
        sm: "column",
        md: "row",
        lg: "row",
        xl: "row",
      }}
      sx={{
        height: "100vh",
        overflow: "auto",

        width: "100vw",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            // background: "rgba(0, 0, 0, 0.015)",
            borderRadius: 2,
            borderColor: "rgb(0, 0, 0, 0.02)",
          },
        },
      }}
    >
      <Stack
        sx={{
          mt: 3,
          flex: 1,
          p: 4,
          pb: 8,
        }}
      >
        <Stack
          sx={{
            pt: {
              xs: 2,
              xsm: 2,
              sm: 2,
              md: 8,
              lg: 10,
              xl: 10,
            },
            alignSelf: "center",
            flex: 1,

            width: {
              xs: "100%",
              xsm: "100%",
              sm: 420,
              md: 480,
              lg: 480,
              xl: 480,
            },
          }}
        >
          <Typography variant="h4" component="h4" sx={{}}>
            <b>Password reset</b>
          </Typography>
          <Typography sx={{ mb: 1 }}>
            {done
              ? `A password reset link has been sent to ${formData.email}.`
              : "Enter your signup email to receive a password reset link."}
          </Typography>

          <Collapse in={done}>
            <HiCheckCircle color="#00CF49" size={32} />
          </Collapse>

          <Collapse in={!done}>
            <>
              <Stack spacing={3} sx={{ mt: 4 }}>
                <TextField
                  name="email"
                  onBlur={handleBlur}
                  InputLabelProps={{ shrink: true }} // Always shrink the label
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                  variant="outlined"
                  label="Email address"
                  fullWidth
                />
              </Stack>
              <Collapse in={Boolean(serverMessage)}>
                <Typography
                  sx={{
                    mt: 2,
                    color: "#CF0000",
                    ml: 1,
                  }}
                >
                  {serverMessage}
                </Typography>
              </Collapse>
              <Stack>
                <Button
                  fullWidth
                  variant="contained"
                  disableElevation
                  disabled={loading || !validator.isEmail(formData.email)}
                  onClick={login}
                  // color="success"
                  endIcon={
                    loading ? (
                      <CircularProgress size={16} color="inherit" />
                    ) : (
                      <HiArrowLongRight />
                    )
                  }
                  sx={{
                    textTransform: "none",
                    mt: 3,
                    height: 60,
                    borderRadius: 2,
                  }}
                >
                  Get password reset link
                </Button>
                <Typography sx={{ mt: 1 }}>
                  Remember password?{" "}
                  <Button
                    onClick={() => {
                      navigate("/login", { replace: true });
                    }}
                    sx={{ textTransform: "none" }}
                  >
                    Sign in
                  </Button>
                </Typography>
              </Stack>
            </>
          </Collapse>
        </Stack>
      </Stack>

      <Stack
        sx={{
          display: {
            xs: "none",
            xsm: "none",
            sm: "none",
            md: "flex",
            lg: "flex",
            xl: "flex",
          },
          // borderRadius: 12,
          background: "#004167",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          p: 4,
          color: "white",
          // m: 2,
        }}
      >
        <Stack>
          <Typography sx={{ maxWidth: 500 }} variant="h6">
            <b>
              Transform your revision with Qflit: Tackle structured questions,
              get instant feedback, and turn challenges into personalized
              lessons.
            </b>
          </Typography>
          <Typography sx={{ maxWidth: 550, mt: 2 }}>
            Join our community today!
          </Typography>
        </Stack>
        <img
          src={people}
          alt=""
          style={{ height: "30vh", objectFit: "contain" }}
        />
      </Stack>
    </Stack>
  );
};

export default ForgotPassword;
