import { createSlice, createAction } from "@reduxjs/toolkit";

export const cacheBusterSlice = createSlice({
  name: "cacheBuster",
  initialState: {},
  reducers: {
    updateCacheBuster: (state, action) => {
      // Update the cache buster value for the given key to the current timestamp.
      const { key } = action.payload;
      state[key] = Date.now();
    },
  },
});

// Define a thunk to handle the logic of updating cache buster
export const updateCacheBusterAsync = (key) => (dispatch) => {
  dispatch(updateCacheBuster({ key }));
};

// Export the action creator
export const { updateCacheBuster } = cacheBusterSlice.actions;

// Selector to get the cache buster value for a specific key
export const selectCacheBuster = (state, key) => state.cacheBuster[key] || "";

export default cacheBusterSlice.reducer;
