import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useUploadMutation } from "../../api/api-endpoints/documents";
import { IMaskInput } from "react-imask";
import {
  TextField,
  Button,
  Box,
  Typography,
  IconButton,
  Stack,
  InputAdornment,
  Collapse,
  Avatar,
  LinearProgress,
  CircularProgress,
  Alert,
} from "@mui/material";
import FileUploader from "../../components/uploader";
import DeleteIcon from "@mui/icons-material/Delete";
import { removeItemFromProgress } from "../../state/slices/progress";
import { useEditUserMutation } from "../../api/api-endpoints/user";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { HiMiniCreditCard, HiXMark } from "react-icons/hi2";
import { updateUser } from "../../state/slices/user";
import { useSelector } from "react-redux";
import { Refresh } from "@mui/icons-material";
import { useDispatch } from "react-redux";

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00-000000 a 00"
      inputRef={ref}
      onAccept={(value) =>
        onChange({ target: { name: props.name, value: value.toUpperCase() } })
      }
      overwrite={false}
    />
  );
});

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const IdentityVerification = ({ handleClose }) => {
  const [editUser, { isLoading: savingUser }] = useEditUserMutation();
  const dispatch = useDispatch();

  const [selectedFile, setSelectedFile] = React.useState([]);

  const [values, setValues] = React.useState({
    identityNumber: "",
  });

  function validateIDNumber(value) {
    return value.trim() !== "";
    /**
  *    const regex = /^\d{2}-\d{6} [a-zA-Z] \d{2}$/;

    return regex.test(value);
  */
  }

  const uploadProgress = useSelector(
    (state) => state.uploadProgress["verification"]
  );

  const uploadCount = uploadProgress.filter(
    (a) => a.progress !== 100 && !a.status
  ).length;

  const [resultAlert, setResultAlert] = React.useState({
    open: false,
    message: null,
    type: null,
    target: null,
  });

  const showResultAlert = (payload) => {
    setResultAlert(payload);
    setTimeout(() => {
      resetResultAlert();
    }, 5000);
  };

  const resetResultAlert = () => {
    setResultAlert({
      open: false,
      message: null,
      type: null,
      target: null,
    });
  };

  const user = useSelector((state) => state.user?.user);
  const { verificationStatus } = user;

  const handleSave = () => {
    editUser({
      identification: {
        documents: [selectedFile[0]._id, selectedFile[1]._id],
        identityNumber: values.identityNumber,
      },
    })
      .unwrap()
      .then((updatedUserData) => {
        dispatch(updateUser({ user: updatedUserData.user }));
        showResultAlert({
          open: true,
          message:
            "Your application for verification has been submitted. You can now close this popup.",
          type: "success",
          target: "profile",
        });
      })
      .catch((error) => {
        console.log("Error", error.message);
        showResultAlert({
          open: true,
          message: "Sorry, there was an error submitting your application.",
          type: "error",
          target: "profile",
        });
      });
  };

  return (
    <>
      <Typography variant="h6">
        <b>Apply for identity verification</b>
      </Typography>
      <Typography variant="caption" sx={{ mb: 3, maxWidth: 350 }}>
        Please attach a document or a picture of your{" "}
        {user?.accountType === "personal"
          ? "national ID"
          : "business registration papers"}{" "}
        and provide your{" "}
        {user?.accountType === "personal" ? "ID number" : "registration number"}{" "}
      </Typography>

      <Stack
        sx={{
          mb: 3,
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              background: "rgba(0, 0, 0, 0.025)",
              borderRadius: 2,
              borderColor: "white",
            },
          },
        }}
        spacing={2}
      >
        <TextField
          sx={{}}
          fullWidth
          placeholder="--- ---- ---"
          name="idNumber"
          label={
            user.accountType === "personal"
              ? "ID Number"
              : "Registration number"
          }
          value={values.identityNumber}
          onChange={(e) => {
            setValues((prev) => ({
              ...prev,
              identityNumber: e.target.value,
            }));
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <HiMiniCreditCard />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Stack sx={{ mb: 2 }}>
        <FileUploader
          docType="mixed"
          accept="image/document"
          resource="new-campaign"
          maxFiles={2}
          label={
            <b>
              Drop your{" "}
              {user.accountType === "personal"
                ? "National ID"
                : "Business Registration Papers"}{" "}
              and Proof of Address here, or click to select.
            </b>
          }
          files={selectedFile}
          setFiles={setSelectedFile}
        />
      </Stack>

      <Collapse in={resultAlert.open}>
        <Alert
          sx={{ mt: 3, borderRadius: 3, p: 2, mb: 3 }}
          onClose={() => {
            resetResultAlert();
          }}
          severity={resultAlert.type || "info"}
        >
          {resultAlert.message}
        </Alert>
      </Collapse>

      <Stack spacing={2} direction="row">
        {verificationStatus === "Pending" && (
          <Button
            onClick={handleClose}
            disableElevation
            variant="outlined"
            startIcon={<HiXMark />}
            color="warning"
            sx={{
              textTransform: "none",
              borderRadius: 32,
              height: 50,
              minWidth: 130,
            }}
          >
            Close
          </Button>
        )}
        {verificationStatus !== "Pending" && (
          <Button
            onClick={handleSave}
            disableElevation
            color="success"
            startIcon={
              uploadCount > 0 || savingUser ? (
                <CircularProgress size={15} />
              ) : null
            }
            fullWidth
            disabled={
              !validateIDNumber(values.identityNumber) ||
              uploadCount > 0 ||
              !(selectedFile[0]?._id && selectedFile[1]?._id) ||
              savingUser
            }
            variant="contained"
            sx={{
              mt: 2,
              borderRadius: 32,
              height: 50,
              textTransform: "none",
              flex: 1,
            }}
          >
            {uploadCount > 0
              ? "Uploading file. Please wait ..."
              : "Save Changes"}
          </Button>
        )}
      </Stack>
    </>
  );
};

export default IdentityVerification;
