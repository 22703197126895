import React, { useState } from "react";
import {
  Stack,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Collapse,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { constants } from "../../utils/config";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setUser } from "../../state/slices/user";
import { HiArrowLongRight } from "react-icons/hi2";

import { motion, AnimatePresence } from "framer-motion";
import people from "../../assets/people.webp";

const containerVariants = {
  hidden: { opacity: 0, x: "100vw" },
  visible: { opacity: 1, x: 0 },
  exit: { x: "-100vw" },
};

const handleBlur = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    confirmPassword: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);

  const [passwordMismatch, setPasswordMismatch] = React.useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "password" || name === "confirmPassword") {
      if (
        value ===
        (name === "password" ? formData.confirmPassword : formData.password)
      ) {
        setPasswordMismatch(false);
      }
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "email" ? value.toLowerCase() : value,
    }));
  };
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [serverMessage, setServerMessage] = useState(null);

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  React.useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [searchParams, navigate, token]);

  React.useEffect(() => {
    if (serverMessage) {
      const timer = setTimeout(() => {
        setServerMessage(null);
      }, 5000); // Clears the message after 5 seconds

      return () => clearTimeout(timer); // Clearing the timer if the component unmounts
    }
  }, [serverMessage]);

  const resetPwd = async () => {
    if (formData.password !== formData.confirmPassword) {
      setPasswordMismatch(true);
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`${constants.apiUrl}/users/resetPassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
          token,
        }),
      });

      const data = await response.json();

      if (data?.user) {
        dispatch(setUser(data));
        if (data.user?.stripeSubscriptionId) {
          navigate("/", { replace: true });
        } else {
          navigate("/subscription", { replace: true });
        }
      } else {
        setServerMessage(data.error || "An unexpected error occurred.");
      }
    } catch (error) {
      setServerMessage("An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack
      direction={{
        xs: "column",
        xsm: "column",
        sm: "column",
        md: "row",
        lg: "row",
        xl: "row",
      }}
      sx={{
        height: "100vh",
        overflow: "auto",

        width: "100vw",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            // background: "rgba(0, 0, 0, 0.015)",
            borderRadius: 2,
            borderColor: "rgb(0, 0, 0, 0.02)",
          },
        },
      }}
    >
      <Stack
        sx={{
          mt: 3,
          flex: 1,
          p: 4,
          pb: 8,
        }}
      >
        <Stack
          sx={{
            pt: {
              xs: 2,
              xsm: 2,
              sm: 2,
              md: 8,
              lg: 10,
              xl: 10,
            },
            alignSelf: "center",
            flex: 1,

            width: {
              xs: "100%",
              xsm: "100%",
              sm: 420,
              md: 480,
              lg: 480,
              xl: 480,
            },
          }}
        >
          <Typography variant="h3" component="h3" sx={{}}>
            <b>Reset password</b>
          </Typography>

          <AnimatePresence mode="wait">
            <motion.div
              key="step1"
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <Stack spacing={4} sx={{ mt: 4 }}>
                <TextField
                  name="password"
                  inputProps={{ minLength: 7 }}
                  helperText="Minimum 7 chars"
                  value={formData.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  InputLabelProps={{ shrink: true }}
                  type="password"
                  placeholder="Password"
                  variant="outlined"
                  label="Enter Password"
                  fullWidth
                />
                <TextField
                  name="confirmPassword"
                  onBlur={handleBlur}
                  type="password"
                  inputProps={{ minLength: 7 }}
                  InputLabelProps={{ shrink: true }} // Always shrink the label
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  placeholder="Confirm password"
                  variant="outlined"
                  label="Confirm password"
                  fullWidth
                />
              </Stack>

              <Collapse in={Boolean(serverMessage)}>
                <Typography
                  sx={{
                    mt: 2,
                    color: "#CF0000",
                    ml: 1,
                  }}
                >
                  {serverMessage}
                </Typography>
              </Collapse>
              <Collapse in={passwordMismatch}>
                <Typography
                  sx={{
                    mt: 2,
                    ml: 1,
                    color: "#E27720",
                  }}
                >
                  Hmm... those passwords aren't twins. Can we try that again?
                </Typography>
              </Collapse>

              <Stack>
                <Button
                  fullWidth
                  variant="contained"
                  disableElevation
                  disabled={loading || formData.password.length < 7}
                  onClick={resetPwd}
                  // color="success"
                  endIcon={
                    loading ? (
                      <CircularProgress size={16} color="inherit" />
                    ) : (
                      <HiArrowLongRight />
                    )
                  }
                  sx={{
                    textTransform: "none",
                    mt: 3,
                    height: 60,
                    borderRadius: 2,
                  }}
                >
                  Reset password
                </Button>
                <Typography sx={{ mt: 1 }}>
                  Remember password?{" "}
                  <Button
                    onClick={() => {
                      navigate("/login");
                    }}
                    sx={{ textTransform: "none" }}
                  >
                    Sign in
                  </Button>
                </Typography>
              </Stack>
            </motion.div>
          </AnimatePresence>
        </Stack>
      </Stack>

      <Stack
        sx={{
          display: {
            xs: "none",
            xsm: "none",
            sm: "none",
            md: "flex",
            lg: "flex",
            xl: "flex",
          },
          // borderRadius: 12,
          background: "#004167",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          p: 4,
          color: "white",
          // m: 2,
        }}
      >
        <Stack>
          <Typography sx={{ maxWidth: 500 }} variant="h6">
            <b>
              Transform your revision with Qflit: Tackle structured questions,
              get instant feedback, and turn challenges into personalized
              lessons.
            </b>
          </Typography>
          <Typography sx={{ maxWidth: 550, mt: 2 }}>
            Join our community today!
          </Typography>
        </Stack>
        <img
          src={people}
          alt=""
          style={{ height: "30vh", objectFit: "contain" }}
        />
      </Stack>
    </Stack>
  );
};

export default ResetPassword;
