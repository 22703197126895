import React from "react";
import { Stack } from "@mui/material";
import { GenericDrawer } from "./widgets";
import {
  AccountStats,
  FundingGuide,
  InvestingCta,
  SecurityCta,
  Transactions,
  ActionTabs,
} from "./components";

export const responsiveProps = (xs, xsm, sm, md, lg, xl) => ({
  xs,
  xsm,
  sm,
  md,
  lg,
  xl,
});

function Home() {
  const [drawer, setDrawer] = React.useState({ open: false, component: <></> });
  return (
    <>
      {/**
       * desktop layout
       */}
      <Stack
        sx={{
          flex: 1,
          display: responsiveProps(
            "none",
            "none",
            "none",
            "none",
            "flex",
            "flex"
          ),
        }}
      >
        <Stack spacing={3} direction="row" sx={{ pl: 4, pr: 4 }}>
          <Stack spacing={2} sx={{ flex: 1 }}>
            <FundingGuide setDrawer={setDrawer} />
          </Stack>
          <Stack spacing={0.5} sx={{ flex: 1 }}>
            <AccountStats />
          </Stack>
          <InvestingCta />
        </Stack>
        <Stack spacing={4} direction="row" sx={{ p: 4 }}>
          <SecurityCta setDrawer={setDrawer} />
          <Transactions />
          <ActionTabs setDrawer={setDrawer} />
        </Stack>
        <GenericDrawer
          open={drawer.open}
          body={drawer.component}
          onClose={() => {
            setDrawer({ open: false, component: <></> });
          }}
        />
      </Stack>
      {/**
       * md layout
       */}
      <Stack
        spacing={3}
        sx={{
          flex: 1,
          display: responsiveProps(
            "none",
            "none",
            "none",
            "flex",
            "none",
            "none"
          ),
        }}
      >
        <Stack spacing={3} direction="row" sx={{ pl: 4, pr: 4 }}>
          <InvestingCta />

          <AccountStats />
        </Stack>
        <Stack spacing={3} direction="row" sx={{ pl: 4, pr: 4 }}>
          <FundingGuide setDrawer={setDrawer} />
          <SecurityCta setDrawer={setDrawer} />
        </Stack>
        <Stack spacing={3} direction="row" sx={{ p: 4 }}>
          <Transactions />
          <ActionTabs setDrawer={setDrawer} />
        </Stack>
        <GenericDrawer
          open={drawer.open}
          body={drawer.component}
          onClose={() => {
            setDrawer({ open: false, component: <></> });
          }}
        />
      </Stack>
      {/**
       * sm layout
       */}
      <Stack
        spacing={3}
        sx={{
          flex: 1,
          display: responsiveProps(
            "none",
            "none",
            "flex",
            "none",
            "none",
            "none"
          ),
        }}
      >
        <Stack direction="row" sx={{ pl: 2, pr: 2 }}>
          <InvestingCta />
        </Stack>
        <Stack spacing={3} direction="row" sx={{ pl: 2, pr: 2 }}>
          <AccountStats />
          <FundingGuide setDrawer={setDrawer} />
        </Stack>

        <Stack spacing={3} direction="row" sx={{ pl: 2, pr: 2 }}>
          <SecurityCta setDrawer={setDrawer} />
        </Stack>
        <Stack spacing={3} direction="row" sx={{ pl: 2, pr: 2, pb: 4 }}>
          <ActionTabs setDrawer={setDrawer} />
        </Stack>

        <GenericDrawer
          open={drawer.open}
          body={drawer.component}
          onClose={() => {
            setDrawer({ open: false, component: <></> });
          }}
        />
      </Stack>
      {/**
       *xs & xsm layout
       */}
      <Stack
        spacing={3}
        sx={{
          display: responsiveProps(
            "flex",
            "flex",
            "none",
            "none",
            "none",
            "none"
          ),
        }}
      >
        <Stack spacing={3} direction="row" sx={{ pl: 2, pr: 2 }}>
          <SecurityCta setDrawer={setDrawer} />
        </Stack>
        <Stack direction="row" sx={{ pl: 2, pr: 2 }}>
          <InvestingCta />
        </Stack>
        <Stack spacing={3} direction="row" sx={{ pl: 2, pr: 2 }}>
          <AccountStats />
        </Stack>

        <Stack spacing={3} direction="row" sx={{ pl: 2, pr: 2 }}>
          <ActionTabs setDrawer={setDrawer} />
        </Stack>

        <Stack spacing={3} direction="row" sx={{ pl: 2, pr: 2, pb: 4 }}>
          <FundingGuide setDrawer={setDrawer} />
        </Stack>
      </Stack>
      <GenericDrawer
        open={drawer.open}
        body={drawer.component}
        onClose={() => {
          setDrawer({ open: false, component: <></> });
        }}
      />
    </>
  );
}

export default Home;
