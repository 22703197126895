import React, { useState, useEffect } from "react";
import "./CircularProgress.css";

import {
  Button,
  Typography,
  Stack,
  Collapse,
  Checkbox,
  Box,
  InputBase,
  Alert,
  AlertTitle,
  CircularProgress,
} from "@mui/material";

import { IoMdCheckmark } from "react-icons/io";
import LinearProgress from "@mui/material/LinearProgress";
import { styled } from "@mui/system";
import { useRequestVerificationMutation } from "../api/api-endpoints/user";
import { HiArrowLongRight, HiXMark } from "react-icons/hi2";

import { useSelector } from "react-redux";

import Cookies from "js-cookie";
import { responsiveProps } from "../pages/home";

//  md: "96.5px"
export const HeaderProgress = ({ loading }) => {
  //  if (!loading) return null;
  return (
    <Stack
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 4,
        background: "red",
        p: 3,
      }}
    >
      <Collapse sx={{}} in={loading}>
        <LinearProgress color="success" sx={{}} />
      </Collapse>
    </Stack>
  );
};

export const CustomInput = ({ label, dropdown, endAdornment, ...props }) => {
  const [focused, setFocused] = useState(false);

  const value = props.inputProps?.value || props.value || props.defaultValue;

  const { InputProps, ...otherProps } = props;
  const { value: v, ...rest } = InputProps || {};

  return (
    <label style={{ width: "100%" }} htmlFor={"id" + props.name}>
      <Box
        sx={{
          width: "100%",
          borderRadius: 2,
          transition: "all ease 0.2s",
          border: focused
            ? "2px solid #8a2be2"
            : "1px solid rgba(0, 0, 0, 0.05)",
          position: "relative",
          "&:hover": {
            borderColor: "rgba(0, 0, 0, 0.87)",
          },
          "&:focus-within": {
            borderColor: "rgba(0, 0, 0, 0.87)",
          },
        }}
      >
        <Typography
          variant="body2"
          component="span"
          sx={{
            position: "absolute",
            left: 10,
            top: value || focused ? 15 : "50%",
            zIndex: 1,
            padding: "0 5px",
            transform:
              value || focused
                ? "scale(0.8) translateY(-50%)"
                : "translateY(-50%)",
            transition: "0.3s",
          }}
        >
          {label}
        </Typography>
        <InputBase
          autoComplete={dropdown ? "new-password" : "on"} // Set autoComplete based on dropdown prop
          {...otherProps}
          {...rest}
          value={value}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          sx={{
            padding: "24px 5px 5px 5px",
            pl: 3,
            pr: endAdornment ? "35px" : 3, // Provide extra padding if there's an adornment
          }}
          id={"id" + props.name}
        />
        {endAdornment && (
          <Box
            sx={{
              position: "absolute",
              right: 10,
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            {endAdornment}
          </Box>
        )}
      </Box>
    </label>
  );
};

export const CustomToggle = ({
  options,
  selectedOption,
  setSelectedOption,
}) => {
  return (
    <Stack
      direction="row"
      style={{
        borderRadius: 32,
        display: "flex",
        justifyContent: "space-between",
        padding: "8px",
        //  background: "rgb(0, 255, 127, 0.07)",
      }}
      sx={{ mt: 1 }}
      spacing={2}
    >
      {options.map((option) => (
        <Button
          key={option.value}
          variant={selectedOption === option.label ? "contained" : "outlined"}
          color="primary"
          disableElevation
          style={{
            flex: 1,
          }}
          sx={{
            textTransform: "none",
            borderRadius: 2,
            p: 2,
          }}
          onClick={() => setSelectedOption(option.label)}
        >
          <Stack sx={{ textAlign: "left" }}>
            <Typography variant="caption">{option.label}</Typography>
            <Typography>{option.description}</Typography>
          </Stack>
        </Button>
      ))}
    </Stack>
  );
};

const CheckedIcon = () => (
  <div
    style={{
      height: 18,
      width: 18,
      borderRadius: 5,
      background: "#1055F5",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      borderStyle: "solid",
      borderColor: "#1055F5",
    }}
  >
    <IoMdCheckmark color="white" />
  </div>
);

export const CustomCheckbox = ({ checked, setChecked }) => {
  return (
    <Checkbox
      sx={{}}
      color="info"
      checked={checked}
      onChange={(e) => {
        if (e.target.checked) {
          setChecked(true);
        } else {
          setChecked(false);
        }
      }}
      icon={
        <div
          style={{
            borderStyle: "solid",
            height: 18,
            width: 18,
            borderRadius: 5,
            borderColor: "#515F6E",
          }}
        />
      }
      checkedIcon={<CheckedIcon />}
    />
  );
};

export const CustomLinearProgress = styled(LinearProgress)({
  width: "50px", // Adjust width as needed
  height: "10px", // Adjust thickness as needed
  borderRadius: "32px",
  "& .MuiLinearProgress-colorPrimary": {
    backgroundColor: "#FFFFE9",
    borderRadius: 32, // Apply borderRadius to the track as well
  },
  "& .MuiLinearProgress-barColorPrimary": {
    backgroundColor: "blue",
    borderRadius: 32, // Apply borderRadius to the fill bar
  },
});

export const CustomCircularProgress = ({
  value = 0,
  size = 120,
  indicatorColor = "#00E156",
  labelColor = "black",
  thin = false,
}) => {
  const radius = size / 2 - 10; // Subtracting the strokeWidth so it fits within the viewBox
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - value * circumference;

  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      className="progress-ring"
      style={{ transform: "scaleY(1)" }}
    >
      <circle
        className="progress-ring__track"
        stroke="rgb(0, 0, 255, 0.05)"
        fill="transparent"
        strokeWidth={thin ? "6" : "10"}
        r={radius}
        cx={size / 2}
        cy={size / 2}
      />
      <circle
        className="progress-ring__circle"
        stroke={indicatorColor}
        fill="transparent"
        strokeWidth={thin ? "6" : "10"}
        strokeDasharray={`${circumference} ${circumference}`}
        style={{ strokeDashoffset }}
        r={radius}
        cx={size / 2}
        cy={size / 2}
      />
      <text
        x="50%"
        y="50%"
        //  transform={`translate(0, ${size}) scaleY(1)`}
        dy=".3em" // Adjusts for vertical alignment
        textAnchor="middle" // Centers the text horizontally
        fill={labelColor} // Color of the text
        fontSize={thin ? "9" : "12"} // Font size, can be adjusted based on size and label length
      >
        {(value * 100).toFixed(0)}%
      </text>
    </svg>
  );
};

export const CustomCircularProgressEmpty = ({
  size = 120,
  labelColor = "black",
}) => {
  const radius = size / 2 - 10; // Subtracting the strokeWidth so it fits within the viewBox

  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      className="progress-ring"
      style={{ transform: "scaleY(1)" }}
    >
      <circle
        className="progress-ring__track"
        stroke="rgb(0, 0, 255, 0.05)"
        fill="transparent"
        strokeWidth="10"
        r={radius}
        cx={size / 2}
        cy={size / 2}
      />

      <text
        x="50%"
        y="50%"
        //  transform={`translate(0, ${size}) scaleY(1)`}
        dy=".3em" // Adjusts for vertical alignment
        textAnchor="middle" // Centers the text horizontally
        fill={labelColor} // Color of the text
        fontSize="12" // Font size, can be adjusted based on size and label length
      >
        0%
      </text>
    </svg>
  );
};

export const VerifyEmailAddress = () => {
  const status =
    useSelector((state) => state.user?.user?.emailVerified) || false;
  const socialLogin =
    useSelector((state) => state.user?.user?.socialLogin) || null;

  const [sendRequest, { isLoading, isSuccess, isError, error }] =
    useRequestVerificationMutation();

  const [dismissed, setDismissed] = React.useState(false);

  useEffect(() => {
    const dismissedTime = Cookies.get("alertDismissedTime");
    if (dismissedTime) {
      const timeElapsed =
        Date.now() - new Date(parseInt(dismissedTime)).getTime();
      const daysElapsed = timeElapsed / (1000 * 3600 * 24);

      if (daysElapsed >= 5) {
        setDismissed(false);
        Cookies.remove("alertDismissedTime");
      } else {
        setDismissed(true);
      }
    }
  }, []);

  const handleSendRequest = async () => {
    await sendRequest();
  };

  if (status === true || socialLogin != null) return null;

  return (
    <Collapse in={dismissed === false}>
      <Alert
        title=""
        sx={{
          p: 2,
          borderRadius: 5,
          m: 3,
          mb: 0,
          mr: responsiveProps(2, 2, 2, 8, 8, 8),
          ml: responsiveProps(2, 2, 2, 8, 8, 8),
        }}
        severity="info"
      >
        <AlertTitle>Secure your account</AlertTitle>
        <Stack sx={{ maxWidth: 600 }}>
          <Typography variant="body1">
            To enhance the security of your account, please verify your email
            address. Click the button below to receive a verification link in
            your email.
          </Typography>

          <Collapse in={isSuccess}>
            <Typography color="success.main" sx={{ mt: 2 }}>
              Verification link sent successfully. Please check your email.
            </Typography>
          </Collapse>
          <Stack sx={{ mt: 2 }} direction="row" spacing={2}>
            {!isSuccess && (
              <Button
                endIcon={
                  !isLoading ? (
                    <HiArrowLongRight />
                  ) : (
                    <CircularProgress color="info" size={15} />
                  )
                }
                onClick={handleSendRequest}
                disabled={isLoading}
                sx={{
                  textTransform: "none",
                  borderRadius: 32,
                }}
              >
                {isLoading ? "Sending..." : "Get verification link"}
              </Button>
            )}

            <Button
              onClick={() => {
                setDismissed(true);
                Cookies.set("alertDismissedTime", Date.now().toString(), {
                  expires: 7,
                });
              }}
              color="warning"
              startIcon={<HiXMark size={14} />}
              disabled={isLoading}
              sx={{
                textTransform: "none",
                borderRadius: 32,
              }}
            >
              Dismiss
            </Button>
          </Stack>

          <Collapse in={isError}>
            <Typography color="error" sx={{ mt: 2 }}>
              Error:{" "}
              {error?.data?.message || "Failed to send verification link."}
            </Typography>
          </Collapse>
        </Stack>
      </Alert>
    </Collapse>
  );
};
