import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import userReducer from "./slices/user";
import snackReducer from "./slices/snack";
import dialogReducer from "./slices/dialog";
import notiCountReducer from "./slices/notify";
import cacheBusterReducer from "./slices/cacheBuster"; // Assuming you've exported it as a default export from its respective file.
import progressReducer from "./slices/progress";
import { apiSlice } from "../api/api.service";
import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "exam",
    "pagination",
    "dialog",
    "snack",
    "uploadProgress",
    "api",
    "cacheBuster",
  ],
};

const rootReducer = combineReducers({
  user: userReducer,
  snack: snackReducer,
  uploadProgress: progressReducer,
  dialog: dialogReducer,
  cacheBuster: cacheBusterReducer,
  notificationCount: notiCountReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);
