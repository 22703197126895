import React, { useState } from "react";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { constants } from "../../utils/config";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../state/slices/user";
import styled from "@emotion/styled";

const AnimatedDot = styled.span`
  animation-name: dotPulse;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;

  &:nth-of-type(1) {
    animation-delay: 0.5s;
  }

  &:nth-of-type(2) {
    animation-delay: 1s;
  }

  &:nth-of-type(3) {
    animation-delay: 1.5s;
  }

  @keyframes dotPulse {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
`;

const Authenticate = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const fetchUserWithToken = React.useCallback(
    async (token) => {
      try {
        const response = await fetch(`${constants.apiUrl}/users/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const user = await response.json();
          dispatch(setUser({ token, user }));
          navigate("/onboarding", { replace: true });
        } else {
          // navigate("/login", { replace: true });
        }
      } catch (error) {
        //navigate("/login", { replace: true });
      } finally {
        //  navigate("/login", { replace: true });
      }
    },
    [dispatch, navigate]
  );

  React.useEffect(() => {
    const url = new URL(window.location.href);
    const token = url.searchParams.get("token");

    if (token) {
      fetchUserWithToken(token);
      // window.history.replaceState({}, document.title, "/auth");
    } else {
      // navigate("/login", { replace: true });
    }
  }, [fetchUserWithToken, navigate]);

  return (
    <Stack
      sx={{
        height: "100vh",
        overflow: "auto",
        width: "100vw",
        justifyContent: "center",
        alignItems: "center",
      }}
      spacing={2}
    >
      <CircularProgress size={48} />
      <Typography variant="h5">
        <b>
          Signing you in <AnimatedDot>.</AnimatedDot>
          <AnimatedDot>.</AnimatedDot>
          <AnimatedDot>.</AnimatedDot>
        </b>
      </Typography>
    </Stack>
  );
};

export default Authenticate;
