import React from "react";
import {
  Stack,
  Drawer,
  IconButton,
  Divider,
  Button,
  Typography,
  ButtonBase,
} from "@mui/material";
import logo from "../assets/logotext.png";
import { MenuRounded } from "@mui/icons-material";
import { HiXMark } from "react-icons/hi2";

import { HiArrowLongRight, HiBell } from "react-icons/hi2";
import { useLocation } from "react-router-dom";
import { setUser } from "../state/slices/user";
import { menuOptions } from "../utils/config";
import { useDispatch } from "react-redux";
import { CiLogout, CiSearch } from "react-icons/ci";
import { useNavigate } from "react-router-dom";

export default function MobileMenu() {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  let paths = location.pathname.split("/").filter(Boolean);

  const lastItem = paths[paths.length - 1];

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  const isActive = (x) => {
    return (
      (paths.length === 0 && x.location === "/") ||
      lastItem?.toLowerCase() === x.title.toLowerCase() ||
      (x.location && lastItem?.toLowerCase() === x.location)
    );
  };

  return (
    <>
      <Drawer
        fullWidth
        onClose={() => {
          setOpen(false);
        }}
        PaperProps={{
          sx: {
            width: "100vw",
            maxWidth: "280px",
          },
        }}
        anchor="left"
        open={open}
        maxWidth="sm"
      >
        <Stack
          sx={{
            p: 3,
            pb: 2,
          }}
        >
          <Stack
            justifyContent="space-between"
            alignItems="center"
            direction="row"
            spacing={2}
          >
            <img
              alt="logo"
              src={logo}
              style={{ width: "auto", height: 25, objectFit: "contain" }}
            />
            <IconButton
              onClick={() => {
                setOpen(false);
              }}
            >
              <HiXMark />
            </IconButton>
          </Stack>
        </Stack>
        <Divider sx={{ opacity: 0.25 }} />

        <Stack
          sx={{
            p: 3,
            pt: 2,
            pb: 0,
            pl: 1,
          }}
        >
          {menuOptions.map((x) => {
            return (
              <Stack sx={{ mb: 1, pl: 2 }}>
                <Typography variant="caption" sx={{ mb: 2, mt: 4 }}>
                  <b>{x.section}</b>
                </Typography>
                <Stack>
                  {x.options.map((x, index) => {
                    return (
                      <ButtonBase
                        disabled={x.disabled || false}
                        onClick={() => {
                          setOpen(false);
                          setTimeout(() => {
                            if (x.url) {
                              window.open(x.url, "_blank");
                              return;
                            }
                            navigate(x.location || x.title.toLowerCase());
                          }, 500);
                        }}
                        sx={{
                          mb: 1,
                          borderRadius: 8,
                          p: 2,
                          borderColor: "rgb(0, 0, 0, 0.015)",
                          justifyContent: "space-between",
                          background: isActive(x) ? "orange" : "inherit",
                          color: isActive(x) ? "white" : "inherit",
                          textAlign: "left",
                        }}
                      >
                        <Stack
                          justifyContent="space-between"
                          spacing={1}
                          sx={{ flex: 1 }}
                          alignItems="center"
                          direction="row"
                        >
                          <Stack
                            sx={{ flex: 1 }}
                            spacing={1}
                            alignItems="center"
                            direction="row"
                          >
                            {x.icon}
                            {isActive(x) ? (
                              <Typography sx={{}} variant="caption">
                                <b>{x.title}</b>
                              </Typography>
                            ) : (
                              <Typography sx={{}} variant="caption">
                                {x.title}
                              </Typography>
                            )}
                          </Stack>
                          {isActive(x) && <HiArrowLongRight />}
                        </Stack>
                      </ButtonBase>
                    );
                  })}
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      </Drawer>
      <IconButton
        onClick={() => {
          setOpen(true);
        }}
      >
        <MenuRounded />
      </IconButton>
    </>
  );
}
