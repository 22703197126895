import { apiSlice } from "../api.service";

const extendedApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    editUser: builder.mutation({
      query: (updates) => ({
        url: `/users/me`,
        method: "PATCH",
        body: updates,
      }),
    }),
    requestVerification: builder.mutation({
      query: () => ({
        url: `/users/requestEmailVerification`,
        method: "POST",
      }),
    }),
    getUser: builder.query({
      query: () => ({
        url: `/users/me`,
        method: "GET",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useEditUserMutation,
  useLazyGetUserQuery,
  useRequestVerificationMutation,
} = extendedApi;
