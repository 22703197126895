import React from "react";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { HiX } from "react-icons/hi"; // Assuming you're using react-icons for the close icon
import { Divider } from "@mui/material";
import { List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import SecurityIcon from "@mui/icons-material/Security";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import LockIcon from "@mui/icons-material/Lock";
import {
  Assignment,
  GroupAdd,
  MonetizationOn,
  Publish,
  Search,
  ShoppingCart,
  Wallet,
} from "@mui/icons-material";

export const GenericDrawer = ({ open, onClose, title, body }) => {
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Stack sx={{ width: "100vw", maxWidth: 450, p: 4 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <IconButton onClick={onClose}>
            <HiX /> {/* Close icon */}
          </IconButton>
        </Stack>
        <Divider sx={{ mt: 2, mb: 2, opacity: 0.2 }} />
        {body}
      </Stack>
    </Drawer>
  );
};

export const FundingInstructions = () => {
  return (
    <>
      <Typography gutterBottom>
        <b>How to Fund Your Account</b>
      </Typography>
      <Divider sx={{ my: 2 }} />
      <List>
        <ListItem>
          <ListItemIcon>
            <AttachMoneyIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Send your funds to 'Klicknown' with a minimum balance of $100 to start your investment journey. Ensure the transaction is completed to our verified account." />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <EmailIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="After making the payment, attach proof of payment in an email to transfers@klicknown.com. Make sure to include the email address associated with your Klicknown account in the correspondence." />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CheckCircleOutlineIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Upon verification of the transaction, your Klicknown balance will be topped up, and you can start investing in projects listed on our platform." />
        </ListItem>
      </List>
      <Divider sx={{ my: 2 }} />
      <Typography variant="body2" sx={{}}>
        For any inquiries or assistance, please contact our support team.
      </Typography>
    </>
  );
};

export const DataSafetyInfo = () => {
  return (
    <>
      <Typography gutterBottom>
        <b>How Klicknown Safeguards Your Data</b>
      </Typography>
      <Divider sx={{ my: 2 }} />
      <List>
        <ListItem>
          <ListItemIcon>
            <SecurityIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Comprehensive Security Measures: We employ state-of-the-art security technologies and practices to protect your personal and financial information from unauthorized access." />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <VerifiedUserIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Data Privacy Compliance: Klicknown adheres to strict data privacy regulations, ensuring your information is handled with the utmost confidentiality and respect." />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <LockIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Encrypted Transactions: All transactions on our platform are encrypted, safeguarding your financial activities and preventing data breaches." />
        </ListItem>
      </List>
      <Divider sx={{ my: 2 }} />
      <Typography variant="body2" sx={{ mt: 2 }}>
        Our commitment to data security is unwavering. For more information on
        our security policies, please contact our support team.
      </Typography>
    </>
  );
};

export const InvestmentGuide = () => {
  return (
    <>
      <Typography gutterBottom>
        <b>How to Invest in Asset Purchase Requests</b>
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Typography variant="body1" paragraph>
        At Klicknown, you have the unique opportunity to support individuals in
        acquiring assets while earning a return on your investment. Here’s how
        you can participate:
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            <Search color="primary" /> {/* Ensure you import this icon */}
          </ListItemIcon>
          <ListItemText primary="Explore Requests: Discover a variety of asset purchase requests submitted by individuals. These requests cover everything from business equipment to personal vehicles." />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Wallet color="primary" />{" "}
            {/* Ensure this icon is imported if not already */}
          </ListItemIcon>
          <ListItemText primary="Contribute Financially: Choose a request to contribute towards, funding a portion of the asset's cost. Your contribution helps fulfill the asset purchase, enabling the requester's goals." />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <MonetizationOn color="primary" />{" "}
            {/* Ensure you import this icon */}
          </ListItemIcon>
          <ListItemText primary="Receive Repayments: As the asset is put to use, repayments are made according to the agreed terms. Your share of these repayments is proportional to your investment, providing a return over time." />
        </ListItem>
      </List>
      <Typography variant="body1" paragraph>
        Investing in asset purchase requests not only provides financial returns
        but also supports individuals and businesses in achieving their
        objectives. It's a way to make a meaningful impact while diversifying
        your investment portfolio.
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Typography variant="body2">
        Please consider the risks associated with investing and consult with our
        support team for any questions or to explore current investment
        opportunities.
      </Typography>
    </>
  );
};

export const AssetPurchaseGuide = () => {
  return (
    <>
      <Typography gutterBottom>
        <b>Guide to Submitting Your Asset Purchase Request</b>
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Typography variant="body1" paragraph>
        Looking to acquire an asset but need financial support? Klicknown
        connects you with potential investors ready to contribute towards your
        goal. Follow these steps to submit your request:
      </Typography>
      <List>
        <ListItem>
          <ListItemIcon>
            <Assignment color="primary" />{" "}
          </ListItemIcon>
          <ListItemText primary="Prepare Your Request: Include detailed information about the asset you wish to purchase—its purpose, cost, and how it will generate returns or value." />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Publish color="primary" /> {/* Ensure you import this icon */}
          </ListItemIcon>
          <ListItemText primary="Submit Your Request: Use our platform to submit your asset purchase request. Ensure all required fields are completed accurately to expedite the review process." />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <GroupAdd color="primary" /> {/* Ensure you import this icon */}
          </ListItemIcon>
          <ListItemText primary="Engage with Investors: Once your request is live, potential investors can view and decide to fund your asset purchase. Be ready to answer any questions or provide additional information." />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <ShoppingCart color="primary" /> {/* Ensure you import this icon */}
          </ListItemIcon>
          <ListItemText primary="Acquire Your Asset: After your request is fully funded, you'll receive the funds to proceed with the purchase. Subsequent repayments are made according to the terms agreed with your investors." />
        </ListItem>
      </List>
      <Typography variant="body1" paragraph>
        Submitting an asset purchase request on Klicknown is a great way to
        achieve your goals with the support of a community of investors. We’re
        here to help you through every step of the process.
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Typography variant="body2">
        If you have any questions or need assistance preparing your request,
        don't hesitate to contact our support team.
      </Typography>
    </>
  );
};
