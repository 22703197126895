import React from "react";
import {
  Stack,
  Drawer,
  Typography,
  IconButton,
  Divider,
  Avatar,
  Collapse,
  LinearProgress,
  Button,
  Badge,
} from "@mui/material";
import { apiSlice } from "../api/api.service";
import { CiLaptop, CiMemoPad } from "react-icons/ci";
import { useLazyGetAlertsQuery } from "../api/api-endpoints/alerts";
import { format } from "date-fns";
import { resetNotificationCount } from "../state/slices/notify";
import { useDispatch } from "react-redux";
import {
  HiArrowLongRight,
  HiCheckBadge,
  HiCreditCard,
  HiXMark,
} from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

export default function NotificationDrawer({ open, setOpen }) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [triggerFetch, { isLoading, isFetching, data: alerts = [] }] =
    useLazyGetAlertsQuery();

  React.useEffect(() => {
    const fetchAlerts = async () => {
      try {
        dispatch(resetNotificationCount());
        await triggerFetch();

        dispatch(
          apiSlice.util.updateQueryData("getAlertCount", undefined, (draft) => {
            if (draft) {
              draft.unreadCount = 0;
            }
          })
        );
      } catch (e) {}
    };

    if (open) {
      fetchAlerts();
    }
  }, [dispatch, open, triggerFetch]);

  React.useEffect(() => {}, []);

  return (
    <Drawer
      fullWidth
      onClose={() => {
        setOpen(false);
      }}
      anchor="right"
      open={open}
      maxWidth="sm"
    >
      <Stack
        sx={{
          width: "100vw",
          maxWidth: "450px",
          p: 3,
          pb: 2,
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Typography>
            <b>Notifications</b>
          </Typography>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <HiXMark />
          </IconButton>
        </Stack>
      </Stack>
      <Divider sx={{ opacity: 0.45 }} />
      <Collapse in={isLoading || isFetching}>
        <LinearProgress color="success" sx={{ borderRadius: 32 }} />
      </Collapse>
      <Stack
        sx={{
          width: "100vw",
          maxWidth: "450px",
          p: 3,
          pt: 2,
        }}
      >
        <Collapse in={!isLoading && !isFetching && alerts.length === 0}>
          <Typography>No alerts to show</Typography>
        </Collapse>
        {alerts.map((item) => {
          return (
            <Stack
              sx={{
                mb: 2,
                borderBottom: 1,
                borderColor: "rgb(0, 0, 0, 0.075)",
                pb: 2,
              }}
            >
              <Stack sx={{}} spacing={2} direction="row">
                <Stack>
                  <Avatar
                    sx={{
                      background:
                        item.severity === "success"
                          ? "#00C42E"
                          : item.severity === "neutral"
                          ? "#0034FF"
                          : "red",
                    }}
                  >
                    {item.type === "lesson" && <CiMemoPad />}
                    {item.type === "subscription" && <HiCreditCard />}
                    {item.type === "verification" ? (
                      item.severity === "success" ? (
                        <HiCheckBadge />
                      ) : (
                        <HiXMark />
                      )
                    ) : null}
                  </Avatar>
                </Stack>
                <Stack>
                  <Typography>
                    <b>{item.title}</b>
                  </Typography>
                  <Typography variant="caption">
                    {format(new Date(item.date), "EEE MMM yyyy")}
                  </Typography>
                  <Typography variant="body2">{item.content}</Typography>
                </Stack>
              </Stack>
              {item.actionCode === "charge_failed" && (
                <Button
                  color="inherit"
                  onClick={() => {
                    navigate("/profile?target=subscription");
                  }}
                  sx={{
                    borderRadius: 32,
                    textTransform: "none",
                    alignSelf: "flex-end",
                    mt: 1,
                  }}
                  endIcon={<HiArrowLongRight />}
                >
                  Update billing details
                </Button>
              )}
            </Stack>
          );
        })}
      </Stack>
    </Drawer>
  );
}
