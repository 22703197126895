import React, { useState } from "react";
import {
  Stack,
  Typography,
  Divider,
  TextField,
  Button,
  CircularProgress,
  Collapse,
} from "@mui/material";
import { useDispatch } from "react-redux";
import Banner from "./banner";
import logo from "../../assets/logo5.png";
import { CustomInput } from "../../components/global";
import { constants } from "../../utils/config";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../state/slices/user";
import { HiArrowLongRight, HiArrowTopRightOnSquare } from "react-icons/hi2";
import validator from "validator";
import { motion, AnimatePresence } from "framer-motion";
import people from "../../assets/people.webp";

const containerVariants = {
  hidden: { opacity: 0, x: "100vw" },
  visible: { opacity: 1, x: 0 },
  exit: { x: "-100vw" },
};

const handleBlur = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);

  const [invalidCreds, setInvalidCreds] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]:
        name === "email" ? value.replace(/\s+/g, "").toLowerCase() : value,
    }));
  };
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [serverMessage, setServerMessage] = useState(null);

  React.useEffect(() => {
    if (serverMessage) {
      const timer = setTimeout(() => {
        setServerMessage(null);
      }, 5000); // Clears the message after 5 seconds

      return () => clearTimeout(timer); // Clearing the timer if the component unmounts
    }
  }, [serverMessage]);

  const login = async () => {
    setLoading(true);

    try {
      const response = await fetch(`${constants.apiUrl}/users/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        dispatch(setUser(data));
        navigate("/", { replace: true });
      } else {
        setServerMessage(data.error || "An unexpected error occurred.");
      }
    } catch (error) {
      console.error("An error occurred while trying to login:", error);
      setServerMessage("An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack
      direction={{
        xs: "column",
        xsm: "column",
        sm: "column",
        md: "row",
        lg: "row",
        xl: "row",
      }}
      sx={{
        height: "100vh",
        overflow: "auto",

        width: "100vw",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            // background: "rgba(0, 0, 0, 0.015)",
            borderRadius: 2,
            borderColor: "rgb(0, 0, 0, 0.02)",
          },
        },
      }}
    >
      <Stack
        sx={{
          mt: {
            xs: 0,
            xsm: 0,
            sm: 3,
            md: 3,
            lg: 3,
            xl: 3,
          },
          flex: 1,
          p: 4,
          pb: 8,
        }}
      >
        <Stack
          sx={{
            pt: {
              xs: 0,
              xsm: 0,
              sm: 0,
              md: 8,
              lg: 10,
              xl: 10,
            },
            alignSelf: "center",
            flex: 1,

            width: {
              xs: "100%",
              xsm: "100%",
              sm: 420,
              md: 480,
              lg: 480,
              xl: 480,
            },
          }}
        >
          <Typography variant="h3" component="h3" sx={{}}>
            <b>Sign In</b>
          </Typography>

          <Button
            onClick={() => {
              window.open("https://klicknown.com", "_blank");
            }}
            color="inherit"
            sx={{
              alignSelf: "flex-start",
              textTransform: "none",
              mt: 1,
              borderRadius: 32,
            }}
            endIcon={<HiArrowTopRightOnSquare />}
          >
            Visit website
          </Button>

          <AnimatePresence mode="wait">
            <motion.div
              key="step1"
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              <Stack spacing={3} sx={{ mt: 4 }}>
                <TextField
                  name="email"
                  onBlur={handleBlur}
                  InputLabelProps={{ shrink: true }} // Always shrink the label
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                  variant="outlined"
                  label="Email address"
                  fullWidth
                />
                <TextField
                  name="password"
                  value={formData.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                  InputLabelProps={{ shrink: true }}
                  type="password"
                  placeholder="Password"
                  variant="outlined"
                  label="Enter Password"
                  fullWidth
                />
              </Stack>

              <Stack sx={{ mt: 1 }} spacing={1} direction="row">
                <Button
                  color="inherit"
                  onClick={() => {
                    navigate("/forgot");
                  }}
                  sx={{ textTransform: "none", borderRadius: 32 }}
                >
                  Forgot Password
                </Button>
              </Stack>
              <Collapse in={Boolean(serverMessage)}>
                <Typography
                  sx={{
                    mt: 2,
                    color: "#CF0000",
                    ml: 1,
                  }}
                >
                  {serverMessage}
                </Typography>
              </Collapse>

              <Stack>
                <Button
                  fullWidth
                  variant="contained"
                  disableElevation
                  disabled={loading || !validator.isEmail(formData.email)}
                  onClick={login}
                  color="success"
                  endIcon={
                    loading ? (
                      <CircularProgress size={16} color="inherit" />
                    ) : (
                      <HiArrowLongRight />
                    )
                  }
                  sx={{
                    textTransform: "none",
                    mt: 3,
                    height: 60,
                    borderRadius: 2,
                  }}
                >
                  Sign In
                </Button>
                <Typography sx={{ mt: 1 }}>
                  No account yet?{" "}
                  <Button
                    color="success"
                    onClick={() => {
                      navigate("/signup");
                    }}
                    sx={{ textTransform: "none", borderRadius: 32 }}
                  >
                    Sign up
                  </Button>
                </Typography>
              </Stack>
              {false && (
                <Stack
                  sx={{ alignItems: "center", ml: 1, mr: 1, mb: 2, mt: 2 }}
                  spacing={3}
                  direction="row"
                >
                  <Divider sx={{ flex: 1, opacity: 0.55 }} />
                  <Typography>or login with</Typography>
                  <Divider sx={{ flex: 1, opacity: 0.55 }} />
                </Stack>
              )}
            </motion.div>
          </AnimatePresence>
        </Stack>
      </Stack>

      <Banner />
    </Stack>
  );
};

export default Login;
