import { createSlice } from "@reduxjs/toolkit";

const progresslice = createSlice({
  name: "uploadProgress",
  initialState: {
    verification: [],
    "new-campaign": [],
  },
  reducers: {
    setUploadProgress(state, action) {
      const {
        resource = "verification",
        key,
        progress,
        status,
      } = action.payload;
      const index = state[resource].findIndex((a) => a.key === key);
      if (index === -1) {
        console.log("payload, add", action.payload);
        state[resource].push({ key, progress });
      } else {
        console.log("payload, update", action.payload);
        state[resource][index] = { key, progress, status };
      }
    },
    removeItemFromProgress(state, action) {
      let resource = action.payload.resource || "verification";
      return {
        ...state,
        [resource]: [
          ...state[resource].filter((a) => a.key !== action.payload.key),
        ],
      };
    },
  },
});

export const { setUploadProgress, removeItemFromProgress } =
  progresslice.actions;
export default progresslice.reducer;
