import { apiSlice } from "../api.service";

const transactionsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTransactions: builder.query({
      query: (filters) => {
        // Convert the filters object to a query string
        const queryString = new URLSearchParams(filters).toString();
        return `/transactions?${queryString}`;
      },
    }),
    getTransaction: builder.query({
      query: (transactionId) => `/transaction/${transactionId}`,
    }),
    createTransaction: builder.mutation({
      query: (body) => ({
        url: "/transactions",
        method: "POST",
        body: body,
      }),
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetTransactionQuery,
  useGetTransactionsQuery,
  useLazyGetTransactionsQuery,
  useCreateTransactionMutation,
} = transactionsApi;
