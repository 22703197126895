import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { constants } from "../../utils/config";
import {
  Stack,
  Typography,
  Button,
  Collapse,
  LinearProgress,
  Chip,
} from "@mui/material";

const EmailVerification = ({ user }) => {
  const [verificationStatus, setVerificationStatus] = useState("verifying");
  const [errorMessage, setErrorMessage] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("token");

  useEffect(() => {
    if (!token) {
      if (!user) {
        navigate("/login");
      } else {
        navigate("/");
      }
    } else {
      const verifyEmail = async () => {
        try {
          const response = await axios.post(
            `${constants.apiUrl}/users/verify-email`,
            { token }
          );
          if (response.data.success) {
            setVerificationStatus("success");
          } else {
            setVerificationStatus("error");
            setErrorMessage(response.data.error);
          }
        } catch (error) {
          setVerificationStatus("error");
          setErrorMessage("An error occurred during the verification process.");
        }
      };

      verifyEmail();
    }
  }, [token, navigate, user]);

  const goToDashboard = () => {
    navigate("/"); // Adjust the path as needed
  };

  const closeWindow = () => {
    window.close();
  };

  return (
    <Stack
      direction={{
        xs: "column",
        xsm: "column",
        sm: "column",
        md: "row",
        lg: "row",
        xl: "row",
      }}
      sx={{
        height: "100vh",
        overflow: "auto",
        width: "100vw",
      }}
    >
      <Stack
        sx={{
          mt: 3,
          flex: 1,
          p: 4,
          pb: 8,
        }}
      >
        <Stack
          sx={{
            pt: {
              xs: 2,
              xsm: 2,
              sm: 2,
              md: 8,
              lg: 10,
              xl: 10,
            },
            alignSelf: "center",
            flex: 1,
            width: {
              xs: "100%",
              xsm: "100%",
              sm: 420,
              md: 480,
              lg: 480,
              xl: 480,
            },
          }}
        >
          <Typography variant="h3" component="h3">
            <b>Verify email address</b>
          </Typography>

          <Collapse
            in={
              verificationStatus === "success" || verificationStatus === "error"
            }
          >
            <div>
              {verificationStatus === "error" && (
                <Chip
                  color="error"
                  sx={{ alignSelf: "flex-start", mt: 2 }}
                  label={`Error: ${errorMessage}`}
                />
              )}
              {verificationStatus === "success" && (
                <Typography
                  sx={{ maxWidth: 300, mt: 2, color: "#00AA1F", ml: 1 }}
                >
                  Email has been successfully verified.
                </Typography>
              )}

              <Stack sx={{ mt: 1 }} direction="row" spacing={2}>
                <Button
                  onClick={goToDashboard}
                  sx={{ mt: 2, textTransform: "none", borderRadius: 32 }}
                >
                  Go to app
                </Button>
                <Button
                  color="warning"
                  onClick={closeWindow}
                  sx={{ mt: 2, textTransform: "none", borderRadius: 32 }}
                >
                  Close
                </Button>
              </Stack>
            </div>
          </Collapse>
          <Collapse in={verificationStatus === "verifying"}>
            <Stack>
              <Typography sx={{ maxWidth: 300, mt: 2 }}>
                We are currently verifying your email address. Please hold on as
                we confirm your details with our server.
              </Typography>

              <LinearProgress sx={{ width: 300, borderRadius: 32, mt: 2 }} />
            </Stack>
          </Collapse>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default EmailVerification;
