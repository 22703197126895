import { createSlice } from "@reduxjs/toolkit";

const snackSlice = createSlice({
  name: "snack",
  initialState: {
    open: false,
    msg: null,
  },
  reducers: {
    updateSnack(state, action) {
      state.open = action.payload.open;
      state.msg = action.payload.msg;
    },
  },
});

export const { updateSnack } = snackSlice.actions;
export default snackSlice.reducer;
