import React, { Suspense } from "react";
import {
  CssBaseline,
  ThemeProvider,
  createTheme,
  Stack,
  CircularProgress,
  Button,
  LinearProgress,
} from "@mui/material";
import DashboardLayout from "./containers/DashboardLayout";
import { motion, AnimatePresence } from "framer-motion";
import ForgotPassword from "./pages/account/forgot";
import ResetPassword from "./pages/account/reset";
import SignUp from "./pages/account/signup";
import Login from "./pages/account/login";
import VerifyEmail from "./pages/account/verify";
import Authenticate from "./pages/account/auth";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./state/store";
import RouteGuard from "./pages/RouteGuard";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

const Home = React.lazy(() => import("./pages/home/index"));
const Profile = React.lazy(() => import("./pages/profile"));
const MyCampaigns = React.lazy(() => import("./pages/my-campaigns"));
const Transactions = React.lazy(() => import("./pages/transactions"));
const Investments = React.lazy(() => import("./pages/investments"));

const theme = createTheme({
  palette: {
    //  mode: "dark",
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
  breakpoints: {
    values: {
      xs: 0,
      xsm: 480, // New breakpoint between xs and sm
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

const pageVariants = {
  initial: {
    opacity: 0,
    y: "100vw",
    //  scale: 0.4,
  },
  in: {
    opacity: 1,
    y: 0,
    // scale: 1,
  },
  out: {
    opacity: 0,
    y: "100vw",
    //  scale: 1.2,
  },
};

const pageTransition = {
  ease: "anticipate",
  duration: 1,
};

function withAnimation(Component) {
  return function AnimatedComponent() {
    return (
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}
      >
        <Component />
      </motion.div>
    );
  };
}

const AnimatedHome = withAnimation(Home);
const AnimatedProfile = withAnimation(Profile);
const AnimatedMyCampaigns = withAnimation(MyCampaigns);
const AnimatedTransactions = withAnimation(Transactions);
const AnimatedInvestments = withAnimation(Investments);

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service
    console.error("Uncaught error:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Stack>
          <h5>Something went wrong.</h5>

          <Button
            onClick={() => {
              window.location.href = "https://app.klicknown.com";
            }}
            sx={{ borderRadius: 32, textTransform: "none", mt: 1 }}
          >
            Back home
          </Button>
        </Stack>
      );
    }

    return this.props.children;
  }
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate
        loading={
          <Stack
            sx={{ flex: 1, justifyContent: "center", alignItems: "center" }}
          >
            <CircularProgress />
          </Stack>
        }
        persistor={persistor}
      >
        <ErrorBoundary>
          <Router>
            <AppContent />
          </Router>
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  );
}

const NotFoundPage = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <h5>404 - Page Not Found</h5>
      <p>The page you are looking for doesn't exist or has been moved.</p>
      <Button
        onClick={() => {
          window.location.href = "https://app.klicknown.com";
        }}
        sx={{ borderRadius: 32, textTransform: "none", mt: 1 }}
      >
        Back home
      </Button>
    </div>
  );
};

function AppContent() {
  const location = useLocation();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AnimatePresence mode="wait">
        <Routes key={location.pathname} location={location}>
          <Route path="/" element={<RouteGuard />}>
            <Route element={<DashboardLayout />}>
              <Route
                index
                element={
                  <Suspense
                    fallback={
                      <Stack
                        sx={{
                          flex: 1,
                        }}
                      >
                        <LinearProgress
                          color="success"
                          sx={{ borderRadius: 32, width: "100%" }}
                        />
                      </Stack>
                    }
                  >
                    <AnimatedHome />
                  </Suspense>
                }
              ></Route>
              <Route
                path="/transactions"
                element={
                  <Suspense
                    fallback={
                      <Stack
                        sx={{
                          flex: 1,
                        }}
                      >
                        <LinearProgress
                          color="success"
                          sx={{ borderRadius: 32, width: "100%" }}
                        />
                      </Stack>
                    }
                  >
                    <AnimatedTransactions />
                  </Suspense>
                }
              ></Route>
              <Route
                path="/investments"
                element={
                  <Suspense
                    fallback={
                      <Stack
                        sx={{
                          flex: 1,
                        }}
                      >
                        <LinearProgress
                          color="success"
                          sx={{ borderRadius: 32, width: "100%" }}
                        />
                      </Stack>
                    }
                  >
                    <AnimatedInvestments />
                  </Suspense>
                }
              ></Route>
              <Route
                path="/campaigns"
                element={
                  <Suspense
                    fallback={
                      <Stack
                        sx={{
                          flex: 1,
                        }}
                      >
                        <LinearProgress
                          color="success"
                          sx={{ borderRadius: 32, width: "100%" }}
                        />
                      </Stack>
                    }
                  >
                    <AnimatedMyCampaigns />
                  </Suspense>
                }
              ></Route>

              <Route
                path="/profile"
                element={
                  <Suspense
                    fallback={
                      <Stack
                        sx={{
                          flex: 1,
                        }}
                      >
                        <LinearProgress
                          color="success"
                          sx={{ borderRadius: 32, width: "100%" }}
                        />
                      </Stack>
                    }
                  >
                    <AnimatedProfile />
                  </Suspense>
                }
              ></Route>
            </Route>
          </Route>

          <Route path="/signup" element={<SignUp />} />
          <Route path="/auth" element={<Authenticate />} />
          <Route path="/login" element={<Login />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/password/reset" element={<ResetPassword />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </AnimatePresence>
    </ThemeProvider>
  );
}

export default App;
