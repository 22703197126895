import { createSlice } from "@reduxjs/toolkit";

const dialogSlice = createSlice({
  name: "dialog",
  initialState: {
    open: false,
    msg: null,
  },
  reducers: {
    updateDialog(state, action) {
      state.open = action.payload.open;
      state.msg = action.payload.msg;
    },
  },
});

export const { updateDialog } = dialogSlice.actions;
export default dialogSlice.reducer;
