import React, { useState } from "react";
import {
  Stack,
  Typography,
  Divider,
  TextField,
  Button,
  CircularProgress,
  Collapse,
  InputAdornment,
  Radio,
  RadioGroup,
  ButtonBase,
} from "@mui/material";
import { IMaskInput } from "react-imask";
import Banner from "./banner";
import { constants } from "../../utils/config";
import { useNavigate } from "react-router-dom";
import {
  HiArrowLongLeft,
  HiArrowLongRight,
  HiCheck,
  HiArrowTopRightOnSquare,
} from "react-icons/hi2";
import validator from "validator";
import { styled } from "@mui/material/styles";
import { motion, AnimatePresence } from "framer-motion";
import { useDispatch } from "react-redux";
import { setUser } from "../../state/slices/user";

const containerVariants = {
  hidden: {
    opacity: 0,
    x: "5vw", // slight movement from the side
    y: "-2.5vh", // slight movement upwards
  },
  visible: {
    opacity: 1,
    x: 0,
    y: 0,
  },
  exit: {
    x: "-5vw", // slight movement to the side
    y: "5vh", // slight movement downwards
  },
};

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    background: "",
    borderRadius: 3, // Increase the vertical padding to increase height
    fontSize: "1.1rem", // Optional: Increase font size if desired

    // Lighter gradient
  },
  "& label.Mui-focused": {
    color: "#007BFF",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    "& fieldset": {
      borderColor: "rgb(2, 26, 24, 0.04)",
      borderStyle: "solid",
      borderWidth: 1,
    },
    "&:hover fieldset": {
      borderColor: "rgb(2, 26, 24)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#007BFF",
      borderWidth: "1.5px",
      boxShadow: "0 0 15px rgba(0, 123, 255, 0.2)", // Enhanced bluish shadow on focus
    },
  },
  "& .MuiOutlinedInput-input::placeholder": {
    color: "#c0c0c0", // Lighter color for the placeholder text
    opacity: 0.7, // Make sure the placeholder is fully visible
  },
});

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00 000 0000"
      inputRef={ref}
      onAccept={(value) =>
        onChange({ target: { name: props.name, value: value.toUpperCase() } })
      }
      overwrite={false}
    />
  );
});

const handleBlur = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
};

const SignUp = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    phone: "",
    businessName: "",
  });

  const [loading, setLoading] = useState(false);

  const [emailExists, setEmailExists] = useState(null);

  const [hasSocialLogin, setHasSocialLogin] = useState(null);

  const [error, setError] = useState(false);
  const [passwordMismatch, setPasswordMismatch] = React.useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "password" || name === "confirmPassword") {
      if (
        value ===
        (name === "password" ? formData.confirmPassword : formData.password)
      ) {
        setPasswordMismatch(false);
      }
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]:
        name === "email" ? value.replace(/\s+/g, "").toLowerCase() : value,
    }));
  };

  const nextStep = () => setStep(step < 3 ? step + 1 : step);
  const previousStep = () => setStep(step > 1 ? step - 1 : step);

  const dispatch = useDispatch();

  const [accountType, setAccountType] = React.useState("personal");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      setPasswordMismatch(true);
      return;
    }

    setPasswordMismatch(false);

    setLoading(true);

    try {
      let payload = { ...formData };

      if (accountType === "business") {
        payload.representative = {
          firstName: formData.firstName,
          lastName: formData.lastName,
        };
        delete payload.firstName;
        delete payload.lastName;
        payload.accountType = "business";
      } else {
        payload.accountType = "personal";
      }
      const response = await fetch(`${constants.apiUrl}/users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...payload,
          phone: "+263 " + formData.phone,
        }),
      });

      const data = await response.json();

      if (data?.user) {
        dispatch(setUser(data));
        navigate("/", { replace: true });
      } else {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3500);
      }
    } catch (error) {
      console.error("Network error:", error);
    } finally {
      setLoading(false);
    }
  };

  const navigate = useNavigate();

  console.log(accountType);

  const checkEmail = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${constants.apiUrl}/users/checkEmail?email=${formData.email}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (data.exists === true) {
        setEmailExists(data.exists);
        setHasSocialLogin(data.hasSocialLogin);
        setTimeout(() => {
          setEmailExists(null);
          setHasSocialLogin(null);
        }, 3500);
      } else {
        nextStep();
      }
    } catch (error) {
      console.error("Error checking email:", error);
      setEmailExists(null);
      setHasSocialLogin(null);
    }
    setLoading(false);
  };

  return (
    <Stack
      direction={{
        xs: "column",
        xsm: "column",
        sm: "column",
        md: "row",
        lg: "row",
        xl: "row",
      }}
      sx={{
        height: "100vh",
        overflow: "auto",

        width: "100vw",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            // background: "rgba(0, 0, 0, 0.015)",
            borderRadius: 2,
            borderColor: "rgb(0, 0, 0, 0.02)",
          },
        },
      }}
    >
      <Stack
        sx={{
          mt: 3,
          flex: 1,
          p: 4,
          pb: 8,
        }}
      >
        <Stack
          sx={{
            pt: {
              xs: 2,
              xsm: 2,
              sm: 2,
              md: 8,
              lg: 10,
              xl: 10,
            },
            alignSelf: "center",
            flex: 1,

            width: {
              xs: "100%",
              xsm: "100%",
              sm: 420,
              md: 480,
              lg: 480,
              xl: 480,
            },
          }}
        >
          <Typography variant="h3" component="h3" sx={{}}>
            <b>Sign up</b>
          </Typography>

          <Button
            onClick={() => {
              window.open("https://klicknown.com", "_blank");
            }}
            color="inherit"
            sx={{
              alignSelf: "flex-start",
              textTransform: "none",
              mt: 1,
              borderRadius: 32,
            }}
            endIcon={<HiArrowTopRightOnSquare />}
          >
            Visit website
          </Button>

          <Typography sx={{ mt: 1, fontWeight: "400" }}>
            Share the details below to launch your account. Registered already?{" "}
            <Button
              color="success"
              onClick={() => {
                navigate("../login");
              }}
              sx={{ borderRadius: 32, textTransform: "none", mt: -0.4 }}
            >
              Sign in
            </Button>
          </Typography>

          <AnimatePresence mode="wait">
            {step === 1 && (
              <motion.div
                key="step1"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <StyledTextField
                  name="email"
                  sx={{ mt: 4 }}
                  onBlur={handleBlur}
                  InputLabelProps={{ shrink: true }} // Always shrink the label
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="investor@klicknown.com"
                  variant="outlined"
                  label="Email address"
                  fullWidth
                />
                <Collapse in={emailExists}>
                  <Typography
                    sx={{
                      mt: 2,
                      color: hasSocialLogin ? "#005650" : "#E27720",
                      ml: 1,
                    }}
                  >
                    {hasSocialLogin
                      ? "Did you sign up using Google/Facebook? Try logging in with that instead!"
                      : emailExists
                      ? "Sorry, this email address is taken."
                      : ""}
                  </Typography>
                </Collapse>

                <Button
                  fullWidth
                  variant="contained"
                  disableElevation
                  disabled={loading || !validator.isEmail(formData.email)}
                  onClick={checkEmail}
                  color="success"
                  endIcon={
                    loading ? (
                      <CircularProgress size={16} color="inherit" />
                    ) : (
                      <HiArrowLongRight />
                    )
                  }
                  sx={{
                    textTransform: "none",
                    mt: 3,
                    height: 60,
                    borderRadius: 2,
                  }}
                >
                  Next step
                </Button>
                {false && (
                  <Stack
                    sx={{ alignItems: "center", ml: 1, mr: 1, mb: 2, mt: 2 }}
                    spacing={3}
                    direction="row"
                  >
                    <Divider sx={{ flex: 1, opacity: 0.55 }} />
                    <Typography>or sign up with</Typography>
                    <Divider sx={{ flex: 1, opacity: 0.55 }} />
                  </Stack>
                )}
              </motion.div>
            )}

            {step === 2 && (
              <motion.div
                key="step2"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    nextStep();
                  }}
                >
                  <Stack>
                    <Typography sx={{ mt: 2, mb: 1 }} variant="caption">
                      <b>Account Type</b>
                    </Typography>
                    <Stack
                      sx={{
                        background: "#E7FFF8",
                        borderRadius: 32,
                        p: 0.5,
                        alignSelf: "flex-start",
                        transition: "all ease 1s",
                      }}
                      direction="row"
                    >
                      <ButtonBase
                        onClick={() => {
                          setAccountType("personal");
                        }}
                        sx={{
                          borderRadius: 32,
                          p: 0.8,
                          px: 1.5,
                          color: accountType === "personal" ? "white" : "black",
                          background:
                            accountType === "personal"
                              ? "black"
                              : "rgb(0, 0, 0, 0)",
                        }}
                      >
                        <Typography variant="caption">Personal</Typography>
                      </ButtonBase>
                      <ButtonBase
                        onClick={() => {
                          setAccountType("business");
                        }}
                        sx={{
                          justifyContent: "center",
                          p: 0.8,
                          px: 1.5,
                          borderRadius: 32,
                          color: accountType !== "personal" ? "white" : "black",
                          background:
                            accountType !== "personal"
                              ? "black"
                              : "rgb(0, 0, 0, 0)",
                        }}
                      >
                        <Typography variant="caption">Business</Typography>
                      </ButtonBase>
                    </Stack>
                  </Stack>
                  <Stack sx={{ mt: 4 }}>
                    <Stack
                      spacing={3}
                      direction={{
                        xs: "column",
                        mxs: "row",
                        sm: "row",
                        lg: "row",
                      }}
                    >
                      <StyledTextField
                        onBlur={handleBlur}
                        InputLabelProps={{ shrink: true }} // Always shrink the label
                        name="firstName"
                        value={formData.firstName}
                        required
                        inputProps={{ minLength: 2 }}
                        onChange={handleChange}
                        placeholder="First name"
                        variant="outlined"
                        label="First Name"
                        fullWidth
                      />
                      <StyledTextField
                        name="lastName"
                        required
                        inputProps={{ minLength: 2 }}
                        onBlur={handleBlur}
                        InputLabelProps={{ shrink: true }} // Always shrink the label
                        value={formData.lastName}
                        onChange={handleChange}
                        placeholder="Family name"
                        variant="outlined"
                        label="Last Name"
                        fullWidth
                      />
                    </Stack>
                  </Stack>

                  {accountType === "business" && (
                    <Stack
                      spacing={3}
                      sx={{ mt: 4 }}
                      direction={{
                        xs: "column",
                        mxs: "row",
                        sm: "row",
                        lg: "row",
                      }}
                    >
                      <StyledTextField
                        onBlur={handleBlur}
                        InputLabelProps={{ shrink: true }} // Always shrink the label
                        name="businessName"
                        value={formData.businessName}
                        required
                        inputProps={{ minLength: 2 }}
                        onChange={handleChange}
                        placeholder="Registered name"
                        variant="outlined"
                        label="Business name"
                        fullWidth
                      />
                    </Stack>
                  )}
                  <Stack
                    spacing={3}
                    sx={{ mt: 4 }}
                    direction={{
                      xs: "column",
                      mxs: "row",
                      sm: "row",
                      lg: "row",
                    }}
                  >
                    <StyledTextField
                      onBlur={handleBlur}
                      InputProps={{
                        inputComponent: TextMaskCustom,
                        startAdornment: (
                          <InputAdornment position="start">+263</InputAdornment>
                        ),
                      }}
                      InputLabelProps={{ shrink: true }} // Always shrink the label
                      name="phone"
                      value={formData.phone}
                      required
                      inputProps={{ minLength: 2 }}
                      onChange={handleChange}
                      placeholder="7x xxx xxxx"
                      variant="outlined"
                      label="Phone number"
                      fullWidth
                    />
                  </Stack>
                  <Stack sx={{ mt: 3 }} spacing={2} direction="row">
                    <Button
                      color="success"
                      startIcon={<HiArrowLongLeft />}
                      sx={{
                        textTransform: "none",
                        height: 60,
                        borderRadius: 2,
                      }}
                      onClick={previousStep}
                      variant="outlined"
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      disableElevation
                      type="submit"
                      color="success"
                      endIcon={<HiArrowLongRight />}
                      sx={{
                        textTransform: "none",
                        height: 60,
                        borderRadius: 2,
                        flex: 1,
                      }}
                    >
                      Next step
                    </Button>
                  </Stack>
                </form>
              </motion.div>
            )}

            {step === 3 && (
              <motion.div
                key="step3"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <form onSubmit={handleSubmit}>
                  <Stack
                    spacing={3}
                    sx={{ mt: 4 }}
                    direction={{
                      xs: "column",
                      mxs: "row",
                      sm: "row",
                      lg: "row",
                    }}
                  >
                    <StyledTextField
                      name="password"
                      value={formData.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      InputLabelProps={{ shrink: true }}
                      type="password"
                      placeholder="New password"
                      variant="outlined"
                      label="Password"
                      inputProps={{ minLength: 7 }}
                      helperText="Minimum 7 chars"
                      fullWidth
                    />
                    <StyledTextField
                      name="confirmPassword"
                      required
                      InputLabelProps={{ shrink: true }}
                      onBlur={handleBlur}
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      type="password"
                      variant="outlined"
                      label="Confirm Password"
                      placeholder="Repeat password"
                      fullWidth
                    />
                  </Stack>
                  <Collapse in={passwordMismatch}>
                    <Typography
                      sx={{
                        mt: 2,
                        ml: 1,
                        color: "#E27720",
                      }}
                    >
                      Hmm... those passwords aren't twins. Can we try that
                      again?
                    </Typography>
                  </Collapse>
                  <Collapse in={error}>
                    <Typography
                      sx={{
                        mt: 2,
                        color: "#E27720",
                        ml: 1,
                      }}
                    >
                      Sorry, there was an error creating your account
                    </Typography>
                  </Collapse>

                  <Stack sx={{ mt: 3 }} spacing={2} direction="row">
                    <Button
                      color="success"
                      startIcon={<HiArrowLongLeft />}
                      sx={{
                        textTransform: "none",
                        height: 60,
                        borderRadius: 2,
                      }}
                      onClick={previousStep}
                      variant="outlined"
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      disableElevation
                      disabled={loading}
                      color="success"
                      endIcon={
                        loading ? (
                          <CircularProgress size={16} color="inherit" />
                        ) : (
                          <HiCheck />
                        )
                      }
                      sx={{
                        textTransform: "none",
                        height: 60,
                        borderRadius: 2,
                        flex: 1,
                      }}
                    >
                      Finish setup
                    </Button>
                  </Stack>
                </form>
              </motion.div>
            )}
          </AnimatePresence>
        </Stack>
      </Stack>
      <Banner />
    </Stack>
  );
};

export default SignUp;
