import { createSlice } from "@reduxjs/toolkit";

const snackSlice = createSlice({
  name: "notificationCount",
  initialState: 0,
  reducers: {
    resetNotificationCount: (state) => 0,
    incrementNotificationCount: (state) => state + 1,
    setNotificationCount: (state, action) => action.payload,
  },
});

// Destructuring the actions to export
export const {
  resetNotificationCount,
  incrementNotificationCount,
  setNotificationCount,
} = snackSlice.actions;

// Exporting the reducer
export default snackSlice.reducer;
